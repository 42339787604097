import { AxiosInstance } from 'axios'
import { IJsonApiSerializer, IJsonResource, IJsonResponse } from '../JsonApiSerializer'

class DirectReservationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async createDirectReservation(params) {
    const { data } = await this.httpClient.post(`/api/direct_reservations`, { ...params })
    const direct_reservation = this.serializer.parseResourceData(
      data.direct_reservation,
      data.direct_reservation.data
    )
    return { direct_reservation, flush: data.direct_reservation.flush }
  }

  public async updateDirectReservation(reservation) {
    const { id } = reservation
    const param = {
      direct_reservation: { ...reservation },
    }

    const { data } = await this.httpClient.patch(`/api/direct_reservations/${id}`, param)

    const updatedReservation = this.serializer.parseResourceData(
      data.reservation,
      data.reservation.data
    )

    return { updatedReservation, flush: data.flush }
  }
  public async updateCheckIn({ id, checkIn }) {
    const param = {
      direct_reservation: { check_in: checkIn },
    }

    const { data } = await this.httpClient.put(
      `/api/direct_reservations/${id}/update_check_in`,
      param
    )

    const updatedReservation = this.serializer.parseResourceData(
      data.reservation,
      data.reservation.data
    )

    return { updatedReservation, flush: data.flush }
  }
  public getReservationIndexFromJson(data) {
    const records = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { records, pagination }
  }

  // ガイドアサイン-直接予約時にエージェントに紐づくガイドを取得する
  public async getReservationGuides(id) {
    const { data } = await this.httpClient.get(
      `/api/direct_reservations/${id}/guide_assigns/direct_reservation_guides`
    )
    const guides = this.serializer.parseResourceData(data.guides.users, data.guides.users.data)
    const guide_calendars = data.guide_calendars
      ? this.serializer.parseResourceData(data.guide_calendars, data.guide_calendars.data)
      : []
    const pagination = data.guides.users.meta
    return { guides, pagination, guide_calendars }
  }

  // ガイドアサイン 直接予約からエージェントとガイドの会話を開始
  public async createGuideConversation(params) {
    const {
      data,
    } = await this.httpClient.post(
      `/api/direct_reservations/${params.id}/guide_assigns/direct_reservation_create_guide_conversation`,
      { ...params.params }
    )
    return { conversationId: data.conversation_id }
  }

  // 直接予約からガイドアサイン
  public async reservationGuideAssign(params) {
    const {
      data,
    } = await this.httpClient.put(
      `/api/direct_reservations/${params.id}/guide_assigns/direct_reservation_assign`,
      { ...params.params }
    )
    const { flush } = data.direct_reservation
    const reservation = this.serializer.parseResourceData(
      data.direct_reservation,
      data.direct_reservation.data
    )

    return { flush }
  }
  // 直接予約からガイドアサイン解除
  public async reservationGuideUnassign(params) {
    const {
      data,
    } = await this.httpClient.put(
      `/api/direct_reservations/${params.id}/guide_assigns/direct_reservation_unassign`,
      { ...params.params }
    )
    const { flush } = data.direct_reservation
    const reservation = this.serializer.parseResourceData(
      data.direct_reservation,
      data.direct_reservation.data
    )

    return { flush }
  }

  // 会話情報を取得する
  public async getReservationMessages(params) {
    const { data } = await this.httpClient.get('/api/messages', { params })
    return { data }
  }

  public getDirectReservationsFromJson(data) {
    const directReservations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { directReservations, pagination }
  }

  public async deleteDirectReservation(id) {
    const { data } = await this.httpClient.delete(`/api/direct_reservations/${id}`)
    return { flush: data.flush }
  }
}

export default DirectReservationService
