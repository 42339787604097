import ClassNames from 'classnames'
import * as React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { postService } from '../../core/services'
import * as constants from '../../static/constants'
import { IPost } from '../../static/interfaces'
import Score from './Score'
import SVG from 'react-inlinesvg'

interface IDetailMarkerProps {
  post: IPost
  liked: boolean
}

interface IMarkerProps {
  post: IPost
}

const Detail: React.FC<IDetailMarkerProps> = ({ post, liked }) => {
  return (
    <DetailMarker
      id={`marker-detail-${post.id}`}
      className="Marker_Detail"
      href={`/posts/${post.slug}`}
      target="_blank"
    >
      <div className="Marker_Image">
        <img src={post.post_image.image_url} alt="" />
        <div className={ClassNames('Marker_Favorite', { liked })}>
          <SVG
            src="/images/icons/favorite.svg"
            className="icon filled"
            aria-hidden="true"
            role="presentation"
          />
          <SVG
            src="/images/icons/favorite_border.svg"
            className="icon outline"
            aria-hidden="true"
            role="presentation"
          />
        </div>
      </div>
      <div className="Marker_Body">
        <p className="Marker_PostName">{post.name}</p>
        {constants.PAYMENT_REQUIRED && (
          <p className="Marker_PostPrice">
            ¥ {post.price} {I18n.t('generic.per_day')}
          </p>
        )}
        <Score score={post.average_review_score} />
      </div>
    </DetailMarker>
  )
}

const Price: React.FC<IMarkerProps> = props => {
  return (
    <PriceMarker className="PriceMarker">
      {constants.PAYMENT_REQUIRED ? <>¥ {props.post.price}</> : 'Post'}
    </PriceMarker>
  )
}

const PriceMarker = styled.div`
  padding: 6px;
  background-color: ${constants.COLORS.white};
  border-radius: 3px;

  .hovered & {
    background-color: ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  &:hover {
    color: ${constants.COLORS.primary};
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -4px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
`

const DetailMarker = styled.a`
  display: block;
  width: 280px;
  height: 320px;
  white-space: initial;
  border-radius: 3px;
  overflow: hidden;
  background-color: ${constants.COLORS.white};
  color: ${constants.COLORS.black};

  img {
    width: 100%;
  }

  .Marker_Body {
    padding: 8px;
  }

  .Marker_Image {
    width: 100%;
    height: 180px;

    > img {
      object-fit: cover;
      height: inherit;
    }
  }

  .Marker_Favorite {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${constants.COLORS.white};
    cursor: pointer;

    &.liked {
      .icon.filled {
        display: block;
      }
      .icon.outline {
        display: none;
      }
    }

    .icon {
      font-size: 20px;
      color: ${constants.COLORS.subText};

      &.filled {
        display: none;
        color: ${constants.COLORS.caution};
      }
    }
  }

  .Marker_PostPrice {
    font-weight: normal;
    margin-top: 8px;
  }

  .Score {
    margin-top: 12px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -4px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
`

function CustomMarker(googleMaps) {
  class Marker extends googleMaps.OverlayView {
    public PADDING_TOP = 66
    public PADDING_LEFT = 16
    public PADDING_RIGHT = 66
    public PADDING_BOTTOM = 16
    public INFO_MARKER_WIDTH = 280
    public INFO_MARKER_HEIGHT = 320
    public latlng: any
    public map: any
    public args: any
    public markerId: number
    public div: any
    public liked: boolean

    constructor(latlng, map, args) {
      super()

      this.latlng = latlng
      this.map = map
      this.args = args
      this.markerId = args.post.id
      this.setMap(map)
    }

    public getMarkerId() {
      return this.markerId
    }

    public showDetail() {
      render(<Detail post={this.args.post} liked={this.liked} />, this.div)
      this.div.style.zIndex = '1200'
      this.adjustMapPan()
    }

    public closeDetail() {
      render(<Price post={this.args.post} />, this.div)
      this.div.style.zIndex = 'auto'
    }

    public handleOnMouseEnter() {
      if (this.div) {
        this.div.classList.add('hovered')
        // this.div.style.zIndex = '1000'
      }
    }

    public handleOnMouseLeave() {
      if (this.div) {
        this.div.classList.remove('hovered')
        // this.div.style.zIndex = 'auto'
      }
    }

    private onAdd() {
      let div = this.div
      this.liked = this.args.post?.user_liked || false

      if (!div) {
        div = this.div = document.createElement('div')
        div.id = `marker_${this.args.post.id}`
        div.classList.add('Marker')
        div.style.zIndex = 'auto'
        div.style.position = 'absolute'
        div.style.display = 'inline-block'
        div.style.whiteSpace = 'nowrap'
        div.style.fontSize = '14px'
        div.style.fontWeight = 'bold'
        div.style.transform = 'translate(-50%, -100%)'
        div.style.boxShadow = '0 1px 3px 0 rgba(21, 27, 38, 0.15)'
        div.style.cursor = 'pointer'

        render(<Price post={this.args.post} />, div)

        googleMaps.event.addDomListener(div, 'click', async event => {
          event.stopPropagation()
          const favoriteElement = event.target.closest('.Marker_Favorite')

          if (favoriteElement) {
            event.preventDefault()
            const { like } = await postService.toggleLike({ ...this.args.post })

            this.args.toggleLike(this.args.post.id, like.isUserLiked)
            this.liked = like.isUserLiked
            return
          }

          this.showDetail()
          this.args.setActiveMarker(this.markerId)
        })

        const panes = this.getPanes()
        panes.floatPane.appendChild(div)
      }
    }

    private draw() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng)

      if (!this.div) {
        return
      }

      this.div.style.left = `${point.x}px`
      this.div.style.top = `${point.y}px`
    }

    private adjustMapPan() {
      const containerPoint = this.getProjection().fromLatLngToContainerPixel(this.latlng)
      const mapWidth = this.map.getDiv().offsetWidth
      const mapHeight = this.map.getDiv().offsetHeight
      const markerPositions = {
        left: containerPoint.x - this.INFO_MARKER_WIDTH / 2,
        right: containerPoint.x + this.INFO_MARKER_WIDTH / 2,
        top: containerPoint.y - this.INFO_MARKER_HEIGHT,
        bottom: containerPoint.y,
      }

      let panX = 0
      let panY = 0

      if (markerPositions.top < 0 + this.PADDING_TOP) {
        panY = markerPositions.top - this.PADDING_TOP
      }

      if (markerPositions.bottom > mapHeight - this.PADDING_BOTTOM) {
        const offset = markerPositions.bottom - mapHeight + this.PADDING_BOTTOM
        panY = offset
      }

      if (markerPositions.left < 0 + this.PADDING_LEFT) {
        panX = markerPositions.left - this.PADDING_LEFT
      }

      if (markerPositions.right > mapWidth - this.PADDING_RIGHT) {
        const offset = markerPositions.right - mapWidth + this.PADDING_RIGHT
        panX = offset
      }

      if (panX !== 0 || panY !== 0) {
        this.map.panBy(panX, panY)
      }
    }

    private onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
    }
  }

  return (latlng, map, args) => new Marker(latlng, map, args)
}

export default CustomMarker
