import ClassNames from 'classnames'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'
import SVG from 'react-inlinesvg'

interface IProps {
  name: string
  field: string
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

const initialParams = new URLSearchParams(window.location.search)
const getInitialValue = () =>
  initialParams.has('guests') ? Number(initialParams.get('guests')) : 0
const getInitialLabelName = initialGuestCount =>
  initialGuestCount > 0 ? `${initialGuestCount} Guests` : I18n.t('generic.guest')

const useGuest = ({ handleOnSubmit, setPanelIsOpen }) => {
  const [labelName, setLabelName] = React.useState(getInitialLabelName(getInitialValue()))
  const [guestCount, setGuestCount] = React.useState(getInitialValue())

  const onClear = React.useCallback(() => {
    setGuestCount(0)
  }, [])

  const hasValue = React.useMemo(() => {
    return guestCount > 0
  }, [guestCount])

  const onApply = React.useCallback(() => {
    const filterValue: any = {
      guests: guestCount,
    }

    handleOnSubmit(filterValue)
    setPanelIsOpen(false)

    const hasGuestCount = guestCount > 0

    if (hasGuestCount) {
      setLabelName(`${guestCount} Guests`)
    } else {
      setLabelName(I18n.t('generic.guest'))
    }
  }, [guestCount, handleOnSubmit, setPanelIsOpen])

  return {
    guestCount,
    setGuestCount,
    hasValue,
    labelName,
    onApply,
    onClear,
  }
}

const GuestFilter: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [isOpen]
  )
  const { handleOnSubmit } = props
  const { hasValue, labelName, onApply, onClear, guestCount, setGuestCount } = useGuest({
    handleOnSubmit,
    setPanelIsOpen,
  })
  const handleChangeGuestCount = amount => {
    setGuestCount(current => (current + amount < 0 ? current : current + amount))
  }

  return (
    <ClickOutside onClickOutside={() => setPanelIsOpen(false)}>
      <GuestFilterWrapper className="GuestFilter">
        <FilterLabel
          className={ClassNames('Filter_Label', { filled: isOpen || hasValue })}
          onClick={() => setPanelIsOpen(!isOpen)}
        >
          {labelName}
          <SVG
            src="/images/icons/keyboard_arrow_down.svg"
            className="icon expand"
            aria-hidden="true"
            role="presentation"
          />
        </FilterLabel>
        {isOpen && (
          <div className="Filter_Main">
            <div className="Filter_Head">
              <div onClick={() => setPanelIsOpen(false)} className="close">
                <SVG
                  src="/images/icons/close.svg"
                  className="icon"
                  aria-hidden="true"
                  role="presentation"
                />
              </div>
            </div>
            <div className="People">
              <div className="People_Label">
                <div>Adult</div>
              </div>
              <div className="People_Control">
                <span className="control" onClick={() => handleChangeGuestCount(-1)}>
                  -
                </span>
                <span className="number">{guestCount}</span>
                <span className="control" onClick={() => handleChangeGuestCount(1)}>
                  +
                </span>
              </div>
            </div>
            <FilterFooter>
              <div className={ClassNames('_clear', { disabled: !hasValue })} onClick={onClear}>
                {I18n.t('generic.clear')}
              </div>
              <div className="_apply" onClick={onApply}>
                {I18n.t('generic.apply')}
              </div>
            </FilterFooter>
          </div>
        )}
      </GuestFilterWrapper>
    </ClickOutside>
  )
}

const FilterLabel = styled.div`
  border: solid 1px ${constants.COLORS.divider};
  display: flex;
  align-items: center;
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 18px;
  cursor: pointer;

  &.filled {
    background-color: ${constants.COLORS.primary};
    border: solid 1px ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  &:hover:not(.filled) {
    border: solid 1px ${constants.COLORS.primary};
    color: ${constants.COLORS.primary};
  }
`

const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 0;

  ._clear,
  ._apply {
    font-weight: bold;
    cursor: pointer;
  }

  ._clear {
    color: ${constants.COLORS.subText};

    &.disabled {
      visibility: hidden;
    }
  }

  ._apply {
    color: ${constants.COLORS.primary};
  }
  .close {
    margin-left: auto;
    cursor: pointer;
  }
`

const GuestFilterWrapper = styled.div`
  position: relative;

  & + & {
    margin-left: 12px;
  }

  .Filter_Main {
    position: absolute;
    left: 0;
    bottom: -8px;
    transform: translateY(100%);
    background-color: ${constants.COLORS.white};
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    padding: 12px 16px;
    border-radius: 4px;
    z-index: 1100;
    .Filter_Head {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        transition: background-color 0.2s;
        cursor: pointer;
        &:hover {
          background-color: ${constants.COLORS.cardBG};
        }
      }
      .icon {
        width: 24px;
        height: 24px;
      }
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      position: fixed;
      top: 52px;
      left: 0;
      right: 0;
      height: calc(100vh - 52px);
      transform: none;
      width: auto;
      max-width: 100vw;
    }
  }

  .People {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 320px;
    margin-top: 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      min-width: auto;
    }
  }

  .People_Label {
    font-size: 16px;
    font-weight: bold;

    > span {
      display: block;
      font-weight: normal;
    }
  }

  .People_Control {
    display: flex;
    align-items: center;

    .control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
      border: solid 1px ${constants.COLORS.divider};

      :hover {
        border: solid 1px ${constants.COLORS.primary};
      }
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        width: 24px;
        height: 24px;
      }
    }

    .number {
      display: block;
      width: 32px;
      text-align: center;
      margin: 0 20px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        margin: 0 8px;
      }
    }
  }
`

export default GuestFilter
