import * as moment from 'moment'
import React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { createGlobalStyle } from 'styled-components'
import * as constants from '../../static/constants'
import { Button } from '../atoms'
import { Form } from '../molecules'
import SVG from 'react-inlinesvg'

const FIELDS = {
  area: 'area',
  date: 'date',
}

interface IArea {
  id: number
  name: string
  name_ja: string
}

const SearchPanelForm: React.FC<{ areas: IArea[] }> = ({ areas }) => {
  const [focused, setFocused] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(null)

  const isMobile = matchMedia(`(max-width: ${constants.BREAKPOINT_TABLET_SMALL}px)`).matches

  const handleCalendarOutSideRange = React.useCallback(calendarMoment => {
    if (calendarMoment.isBefore(moment())) {
      return true
    }
  }, [])
  const onDateChange = React.useCallback((date: moment.Moment | null) => {
    setSelectedDate(date)
  }, [])

  const handleSubmit = React.useCallback(
    (initialValues, values) => {
      let queryParams = `?area_ids[]=${values.area}`

      if (selectedDate !== null) {
        queryParams += `&date=${selectedDate.format('YYYY-MM-DD')}`
      }

      location.href = `/posts/search${queryParams}`
    },
    [selectedDate]
  )

  return (
    <Form fields={FIELDS} handleSubmit={handleSubmit}>
      <SearchInputs>
        <div className="SearchPanel_Inputs">
          <div className="SearchPanel_FormItem">
            <SVG
              src="/images/icons/place.svg"
              className="icon"
              aria-hidden="true"
              role="presentation"
            />
            <select
              className="SearchPanel_AreaInput"
              name="area"
              defaultValue={String(areas[0].id)}
              placeholder="Area"
            >
              {areas.map(area => (
                <option key={area.id} value={area.id}>
                  {I18n.locale === 'ja' && area.name_ja ? area.name_ja : area.name}
                </option>
              ))}
            </select>
          </div>
          <div className="SearchPanel_FormItem">
            <SVG
              src="/images/icons/date_range.svg"
              className="icon"
              aria-hidden="true"
              role="presentation"
            />
            <DateRangePickerCustomStyle />
            <SingleDatePicker
              id="date"
              name="date"
              date={selectedDate}
              placeholder={I18n.locale === 'ja' ? '日付から探す' : 'Select Date'}
              onDateChange={onDateChange}
              focused={focused}
              onFocusChange={changed => setFocused(changed.focused)}
              enableOutsideDays={false}
              isOutsideRange={handleCalendarOutSideRange}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
        <Button primary={true} small={isMobile}>
          {I18n.t('search_pan.search')}
        </Button>
      </SearchInputs>
    </Form>
  )
}

const SearchInputs = styled.div`
  display: flex;
  margin-top: 60px;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    display: block;
    width: 100%;
    margin-top: 0;
  }

  .Button {
    width: 92px;
    margin-left: 12px;
    font-size: 16px;
    font-weight: bold;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-left: 0;
      margin-top: 12px;
    }
  }

  .SearchPanel_Inputs {
    display: flex;
    align-items: center;
    padding: 0 12px;
    border: 1px solid ${constants.COLORS.divider};
    border-radius: 4px;
    background-color: ${constants.COLORS.white};
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      border: 2px solid ${constants.COLORS.divider};
      border-radius: 20px;
      padding: 0 4px;
    }
  }
  .SearchPanel_FormItem {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(50% - 8px);
    min-width: 172px;
    height: 35px;
    font-size: 14px;
    padding: 0 4px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      min-width: auto;
      font-size: 12px;
    }

    > .icon {
      position: absolute;
      left: 6px;
      top: 0;
      bottom: 0;
      height: 34px;
      margin: auto;
      font-size: 34px;
      color: ${constants.COLORS.primary};
      z-index: 10;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      }
      height: 22px;
      font-size: 22px;
    }
  }

  .SearchPanel_AreaInput {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    outline: none;
    border: none;

    width: 100%;
    height: 26px;
    margin-left: 42px;
    padding: 2px 0;
    border-radius: 0;
    &:focus {
      border-bottom: solid 2px ${constants.COLORS.primary};
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin-left: 32px;
    }
  }

  .SearchPanel_DateRangeLabels {
    display: flex;

    > label {
      width: 50%;
    }
  }
`

const DateRangePickerCustomStyle = createGlobalStyle`

  .SingleDatePicker {
    display: block;
    width: 100%;
  }

  .DateRangePickerInput.DateRangePickerInput_1 {
    width: 100%;
    border: none;
  }

  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
    display: none;
  }

  .DateInput {
    width: 100%;
  }
  .DateInput_input{
    font-size: 12px;
    padding: 0;
  }

  .SingleDatePickerInput__withBorder {
    width: 100%;
    border: none;
    padding-left: 48px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      border-radius: 3px;
      padding-left: 36px;
    }
  }

  .CalendarDay__selected_span {
    background: ${constants.COLORS.secondary};
    border: 1px double ${constants.COLORS.secondary};
    color: ${constants.COLORS.white};
  }

  .CalendarDay__selected {
    background: ${constants.COLORS.primary};
    border: 1px double ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  .CalendarDay__selected:hover {
    background: ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${constants.COLORS.secondary};
    border: 1px double ${constants.COLORS.secondary};
    color: ${constants.COLORS.white};
  }
`

export default SearchPanelForm
