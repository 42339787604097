import React, { Children } from 'react'
import { COLORS } from 'static/constants'
import styled from 'styled-components'
import ClassNames from 'classnames'
import SVG from 'react-inlinesvg'

interface IProps {
  iconName?: string
  children?: React.ReactElement
  size?: 'lg' | 'md' | 'sm' | 'ss'
  theme?: 'primary' | 'success' | 'caution' | 'useally'
  translucent?: boolean
  fillBG?: boolean
  disabled?: boolean
  label?: any
  handleClick?: () => void
}

const IconButton: React.VFC<IProps> = ({
  children,
  iconName,
  size = 'lg',
  theme = 'primary',
  fillBG,
  disabled,
  label,
  translucent,
  handleClick,
}) => {
  return (
    <IconButtonWrap
      className={ClassNames(theme, size, { translucent, disabled }, label && 'withLabel')}
      onClick={handleClick}
      fillBG={fillBG}
      size={size}
    >
      <div className="IconBody">
        {children && children}
        {!children && iconName && (
          <SVG
            src={`/images/icons/${iconName}.svg`}
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        )}
      </div>
      {label && <span className="label">{label}</span>}
    </IconButtonWrap>
  )
}

const IconButtonWrap = styled.div`
  color: ${({ fillBG }) => (fillBG ? COLORS.white : COLORS.primary)};
  cursor: pointer;
  transition: opacity 0.2s;
  user-select: none;
  .IconBody {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    border: solid 1px ${COLORS.primary};
    background: ${({ fillBG }) => fillBG && COLORS.primary};
  }
  &.ss {
    .IconBody {
      width: 24px;
      height: 24px;
      .icon {
        width: 14px;
        height: 14px;
      }
    }
    .label {
      font-size: 12px;
    }
  }
  &.sm {
    .IconBody {
      width: 28px;
      height: 28px;
      .icon {
        width: 16px;
        height: 16px;
      }
    }
    .label {
      font-size: 12px;
    }
  }
  &.md {
    .IconBody {
      width: 32px;
      height: 32px;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    .label {
      font-size: 14px;
    }
  }
  &.lg {
    .IconBody {
      width: 40px;
      height: 40px;
      .icon {
        width: 20px;
        height: 20px;
      }
    }
    .label {
      font-size: 14px;
    }
  }
  &.translucent {
    opacity: 0.8;
  }
  &.success {
    color: ${({ fillBG }) => (fillBG ? COLORS.white : COLORS.success)};
    .IconBody {
      border: solid 1px ${COLORS.success};
      background: ${({ fillBG }) => fillBG && COLORS.primary};
    }
  }
  &.caution {
    color: ${({ fillBG }) => (fillBG ? COLORS.white : COLORS.caution)};
    .IconBody {
      border: solid 1px ${COLORS.caution};
      background: ${({ fillBG }) => fillBG && COLORS.caution};
    }
  }
  &.useally {
    color: ${({ fillBG }) => (fillBG ? COLORS.white : COLORS.useally)};
    .IconBody {
      border: solid 1px ${COLORS.useally};
      background: ${({ fillBG }) => fillBG && COLORS.useally};
    }
  }
  &:hover {
    opacity: 0.6;
  }
  &.disabled {
    color: ${({ fillBG }) => (fillBG ? COLORS.white : COLORS.disabled)};
    .IconBody {
      cursor: not-allowed;
      border: solid 1px ${COLORS.disabled};
      background: ${({ fillBG }) => fillBG && COLORS.disabled};
    }
  }
  &.withLabel {
    display: flex;
    align-items: center;
    .label {
      margin-left: 4px;
      white-space: nowrap;
    }
  }
`

export default IconButton
