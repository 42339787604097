import moment from 'moment'
import * as React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { BREAKPOINT_TABLET_SMALL, COLORS, HOURS } from '../../static/constants'
import Select from '../atoms/Select'

interface IProps {
  name: string
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  timeOptionsFilter?: (data: string, index: number, array: string[]) => boolean
  isOutsideRange?: (day) => boolean
  onChangeHandler?: (data) => void
}

const formatBusinessTimeOptions = (filter = (d, i, a) => true) => {
  const times = [{ value: '', label: '-' }]

  HOURS.filter(filter).forEach(time =>
    times.push({
      value: time,
      label: time,
    })
  )
  return times
}

const getIitializeValue = defaultValue => {
  if (defaultValue && defaultValue.length > 0) {
    const initDate = moment(defaultValue)
    const initDateTimeStr = initDate.format('YYYY-MM-DD HH:mm')
    const initDateStr = initDate.format('YYYY/MM/DD')
    const initTimeStr = initDate.format('HH:mm')

    return { initDate, initDateTimeStr, initDateStr, initTimeStr }
  }

  return { initDate: null, initDateTimeStr: '', initDateStr: null, initTimeStr: null }
}

const InputDateTime: React.FC<IProps> = ({
  name,
  defaultValue,
  label,
  error,
  required,
  timeOptionsFilter,
  isOutsideRange,
  onChangeHandler,
}) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false)

  const { initDate, initDateTimeStr, initDateStr, initTimeStr } = getIitializeValue(defaultValue)
  const [selectedDate, setSelectedDate] = React.useState<null | moment.Moment>(initDate)
  const [selectedDateStr, setSelectedDateStr] = React.useState<null | string>(initDateStr)
  const [selectedTimeStr, setSelectedTimeStr] = React.useState<null | string>(initTimeStr)

  const [stringValue, setStringValue] = React.useState<string>(initDateTimeStr)
  const options = formatBusinessTimeOptions(timeOptionsFilter)
  React.useEffect(() => {
    if (selectedDateStr && selectedTimeStr) {
      setSelectedDate(moment(`${selectedDateStr} ${selectedTimeStr}`))
    } else if (selectedDateStr) {
      setSelectedDate(moment(selectedDateStr))
    }
  }, [selectedDateStr, selectedTimeStr])

  React.useEffect(() => {
    if (selectedDate) {
      if (onChangeHandler) {
        onChangeHandler(selectedDate)
      }

      setStringValue(selectedDate.format('YYYY-MM-DD HH:mm'))
    }
  }, [selectedDate])

  const setDate = (date: moment.Moment) => {
    setSelectedDateStr(date.format('YYYY-MM-DD'))
  }

  const setTime = (time: string) => {
    setSelectedTimeStr(time)
  }
  return (
    <S.DateWrapper>
      {label && (
        <p className="InputDate_Title">
          {label}
          {required && <span className="InputDate_Required">{I18n.t('generic.required')}</span>}
        </p>
      )}
      <S.Wrappwers>
        <S.Wrappwer>
          <S.Label>{I18n.t('generic.date')}</S.Label>
          <SingleDatePicker
            date={selectedDate}
            onDateChange={date => setDate(date)}
            focused={isFocused}
            isOutsideRange={isOutsideRange}
            onFocusChange={({ focused }) => setIsFocused(focused)}
            numberOfMonths={1}
          />
        </S.Wrappwer>
        <S.Wrappwer>
          <S.Label>{I18n.t('generic.time')}</S.Label>
          <Select
            name=""
            options={options}
            defaultValue={selectedTimeStr ? selectedTimeStr : options[0].value}
            onChangeHandler={e => {
              setTime(e.target.value)
            }}
          />
        </S.Wrappwer>
      </S.Wrappwers>
      <S.Input
        id={name}
        type="text"
        name={name}
        value={stringValue}
        required={required ? true : false}
        ref={null}
        readOnly={true}
      />
    </S.DateWrapper>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .InputDate_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }
  .InputDate_Required {
    display: inline-block;
    border-radius: 3px;
    color: ${COLORS.white};
    background-color: ${COLORS.caution};
    vertical-align: middle;
    font-size: 11px;
    font-weight: normal;
    padding: 4px 6px;
    margin-left: 8px;
  }
`
S.Wrappwers = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    margin-right: 0px;
  }
`
S.Wrappwer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
  .DateInput_input {
    font-weight: normal;
    padding: 4px 6px;
  }
  select {
    min-width: 132px;
    height: 44px;
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }
`
S.Label = styled.div`
  margin-right: 8px;
`
S.Input = styled.input`
  display: none;
`

export default InputDateTime
