import React from 'react'
import Responsive from 'react-responsive'
import * as constants from '../static/constants'

export const IsDesktop = props => <Responsive {...props} minWidth={constants.BREAKPOINT_DESKTOP} />

export const IsMobileOrTablet = props => (
  <Responsive {...props} maxWidth={constants.BREAKPOINT_DESKTOP - 1} />
)

export const IsTabletOrDesktop = props => (
  <Responsive {...props} minWidth={constants.BREAKPOINT_TABLET_SMALL} />
)

export const IsMobile = props => (
  <Responsive {...props} maxWidth={constants.BREAKPOINT_TABLET_SMALL - 1} />
)

export const IsDesktopXL = props => (
  <Responsive {...props} minWidth={constants.BREAKPOINT_DESKTOP_XL} />
)

export const IsDesktopSmall = props => (
  <Responsive {...props} maxWidth={constants.BREAKPOINT_DESKTOP_XL - 1} />
)
