import moment from 'moment'
import * as React from 'react'
import { COLORS } from 'static/constants'
import styled from 'styled-components'

interface ILabel {
  field: string
  label: string
}

interface IRecords {
  columns: Array<string | ((record: any) => React.ReactElement)>
}

interface IProps {
  label: string
  type: 'success' | 'caution' | 'usually' | 'info' | 'empty'
}

export const getBadgeStateFromWorkflowState = ({ start_at, workflow_state_enum_value }) => {
  if (workflow_state_enum_value === 'requested') {
    return 'usually'
  }
  if (workflow_state_enum_value === 'approved') {
    return 'success'
  }
  if (
    workflow_state_enum_value === 'direct' ||
    workflow_state_enum_value === 'declined' ||
    workflow_state_enum_value === 'canceled'
  ) {
    return 'info'
  }
  if (moment(start_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
    return 'caution'
  }
  return 'empty'
}

const StatusBadge: React.VFC<IProps> = ({ label, type = 'info' }) => {
  return <Badge className={type}>{label}</Badge>
}

const Badge = styled.div`
  width: fit-content;
  max-width: 180px;
  padding: 3px 20px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  height: 2em;

  &.success {
    background: ${COLORS.successBG};
    color: ${COLORS.successText};
  }
  &.caution {
    background: ${COLORS.cautionBG};
    color: ${COLORS.cautionText};
  }
  &.usually {
    background: ${COLORS.useallyBG};
    color: ${COLORS.useallyText};
  }
  &.info {
    background: ${COLORS.infoBG};
    color: ${COLORS.subText};
  }
  &.empty {
    border: 1px solid ${COLORS.successBG};
    color: ${COLORS.successText};
  }
`
export default StatusBadge
