import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostStartTimeService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(params) {
    const { data } = await this.httpClient.post(`/api/post_start_times`, params)
    const { post_start_time, flush } = data
    const startTime = this.serializer.parseResourceData(post_start_time, post_start_time.data)

    return { startTime, flush }
  }

  public async update(id, params) {
    const { data } = await this.httpClient.patch(`/api/post_start_times/${id}`, params)
    const { post_start_time, flush } = data
    const startTime = this.serializer.parseResourceData(post_start_time, post_start_time.data)

    return { startTime, flush }
  }

  public async delete(id) {
    const { data } = await this.httpClient.delete(`/api/post_start_times/${id}`)
    const { flush } = data

    return { flush }
  }
}

export default PostStartTimeService
