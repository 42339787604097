import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import I18n from '../../core/i18n'
import { Tags } from 'components/molecules'
import Typography from 'components/atoms/_layouts/Typography'
import FavoriteButton from 'components/atoms/FavoriteButton'
import SVG from 'react-inlinesvg'

interface IProps {
  post: any
  pickup?: boolean
  likeable?: boolean
  column?: boolean
  noMargin?: boolean
  onToggleLike?: (postId, like) => void
}

const PostCard: React.FC<IProps> = ({ post, pickup, likeable, column, noMargin, onToggleLike }) => {
  const getPostType = (postType: string) => {
    if (postType === 'private') {
      return I18n.locale === 'ja' ? 'プライベート' : 'Private'
    }
    return I18n.locale === 'ja' ? 'グループ' : 'Group'
  }
  return (
    <PostCardWrap
      pickup={pickup}
      href={`/posts/${post.slug}`}
      column={column}
      noMargin={noMargin}
      target="_blank"
    >
      <div className="Image">
        <Tags tags={post.tags} inPostCard={true} />
        {post.post_image && post.post_image.image_url ? (
          <img
            src={post.post_image.image_url}
            alt={post.post_image.description}
            aria-hidden="true"
            loading="lazy"
          />
        ) : (
          <p className="NoImage">No Image</p>
        )}
        {likeable && (
          <div className="ToggleLike">
            <FavoriteButton post={post} onToggleLike={onToggleLike} />
          </div>
        )}
      </div>
      <div className="Info">
        <div className="Header">
          <div className="HeaderInner">
            {post.post_type && (
              <div className={`Category ${post.post_type}`}>{getPostType(post.post_type)}</div>
            )}
            {post.instant_booking && (
              <SVG
                src="/images/icons/bolt.svg"
                className="icon instant-booking"
                aria-hidden="true"
                role="presentation"
              />
            )}
          </div>
          <h3 className="Title">{post.name}</h3>
        </div>
        <div className="Body">
          <div className="Price">
            ¥{post.price} · {post.duration} hours
          </div>
          <div className="PostCard_Score">
            <div className="Score">
              <SVG
                src="/images/icons/star.svg"
                className="icon"
                aria-hidden="true"
                role="presentation"
              />
              <span className="score">{post.average_review_score}</span>
            </div>
            <Typography color="subtext" type="body" size="lg">
              ({I18n.t('review.review_count', { count: post.reviews_count })})
            </Typography>
          </div>
        </div>
      </div>
    </PostCardWrap>
  )
}

const PostCardWrap = styled.a`
  display: block;
  width: ${({ pickup, column }) => (column ? '100%' : pickup ? '100%' : '100%')};
  color: ${constants.COLORS.black};
  flex-shrink: ${({ pickup }) => (pickup ? 0 : 0)};
  border: 1px solid ${constants.COLORS.divider};
  border-radius: 10px;

  .Image {
    position: relative;
    width: 100%;
    height: ${({ pickup, column }) => (pickup || column ? 'auto' : 'auto')};
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      height: ${({ column }) => (column ? 'auto' : 'auto')};
    }
    .ToggleLike {
      position: absolute;
      bottom: 12px;
      right: 12px;
      z-index: 2;
    }
    > img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 5 / 3;
    }
    .NoImage {
      width: 100%;
      height: 100%;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${constants.COLORS.infoBG};
    }
  }

  .Info {
    padding: 15px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin: ${({ pickup }) => (pickup ? '0' : '0')};
    }
  }
  .Header {
    .HeaderInner {
      display: flex;
      align-items: center;
      line-height: 20px;
      margin-bottom: 6px;
    }
    .Category {
      line-height: 16px;
      padding: 1px 8px;
      font-size: 12px;
      font-weight: bold;
      border: 1px solid ${constants.COLORS.primary};
      border-radius: 2px;
      text-transform: capitalize;
      &.private {
        color: ${constants.COLORS.white};
        background-color: ${constants.COLORS.primary};
      }
      &.group {
        color: ${constants.COLORS.primary};
        background-color: ${constants.COLORS.white};
        border-color: ${constants.COLORS.primary};
      }
    }
    .instant-booking {
      color: ${constants.COLORS.useally};
      width: 20px;
      height: 20px;
    }
    .Title {
      width: 100%;
      margin-bottom: 2px;
      line-height: 1.44;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .Body {
    display: flex;
    flex-wrap: wrap;
  }

  .Price {
    font-size: 14px;
    color: ${constants.COLORS.subText};
    margin-right: 10px;
  }

  .PostCard_Score {
    display: flex;
    align-items: center;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      .subtext {
        display: none;
      }
    }
  }

  .Score {
    display: flex;
    align-items: center;
    margin-right: 4px;

    .icon {
      color: ${constants.COLORS.secondary};
      width: 18px;
      height: 18px;
    }

    .score {
      font-size: 14px;
      margin-left: 4px;
      font-weight: bold;
    }
  }

  & + & {
    ${({ column, noMargin }) =>
      !column &&
      !noMargin &&
      `
      margin-left: 24px;
        @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
          margin-left: 12px;
        }
    `}
  }
`

export default PostCard
