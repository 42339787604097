import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import InputLabel from './InputLabel'

interface IProps {
  name: string
  options: any[]
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  selectRef?: any
  disabled?: boolean
  onChangeHandler?(event): any
  onBlurHandler?(event): any
}

const Select: React.FC<IProps> = props => {
  return (
    <SelectWrapper>
      {props.label && <InputLabel label={props.label} required={props.required} />}

      <div className="Select_Main">
        <select
          id={props.name}
          name={props.name}
          required={props.required ? true : false}
          defaultValue={props.defaultValue}
          ref={props.selectRef || null}
          onChange={event => {
            if (typeof props.onChangeHandler === 'function') {
              props.onChangeHandler(event)
            }
          }}
          onBlur={event => {
            if (typeof props.onBlurHandler === 'function') {
              props.onBlurHandler(event)
            }
          }}
          disabled={props.disabled || false}
        >
          {/* <option value="">-</option> */}
          {props.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {props.error && <span className="Select_Error">{props.error}</span>}
      </div>
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .Select_Main {
    flex: 1;
    position: relative;
    &:before,
    &:after {
      position: absolute;
      display: inline-block;
      right: 10px;
      bottom: 0;
      width: 1px;
      height: 10px;
      content: '';
      border: solid;
      border-width: 7px 5px;
    }

    &:before {
      top: 3px;
      border-color: transparent transparent ${constants.COLORS.divider};
    }

    &:after {
      bottom: 3px;
      border-color: ${constants.COLORS.divider} transparent transparent;
    }

    > select {
      appearance: none;
      margin: 0;
      padding: 0;
      background: none transparent;
      vertical-align: middle;
      font-size: inherit;
      color: inherit;
      outline: none;

      width: 100%;
      line-height: 18px;
      border: solid 1px ${constants.COLORS.divider};
      border-radius: 4px;
      padding: 10px 28px 10px 12px;

      &:disabled {
        background-color: ${constants.COLORS.divider};
      }
    }
  }

  .Select_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.COLORS.cautionText};
    font-size: 14px;
  }
`

export default Select
