import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostVideoService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async collect(postId, post_videos_attributes) {
    const params = {
      post: { post_videos_attributes },
    }

    const { data } = await this.httpClient.post(
      `/api/posts/${postId}/collection_post_videos`,
      params
    )

    const { flush } = data
    const postVideos = this.serializer.parseResourceData(data.post_videos, data.post_videos.data)

    return { postVideos, flush }
  }
}

export default PostVideoService
