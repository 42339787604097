import * as Color from 'color'
import ColorString from 'color-string'
import { createGlobalStyle } from 'styled-components'
import * as constants from './constants'

const dateRangePickerCustomStyle = createGlobalStyle`
  .DateRangePicker.DateRangePicker_1 {
    width: 100%;
  }
  .DateRangePickerInput.DateRangePickerInput_1 {
    display: flex;
    align-items: center;
    width: 100%;
    border: solid 1px ${constants.COLORS.divider};
  }
  .DateInput.DateInput_1 {
    width: 50%;
  }
  input#startDate,
  input#endDate {
    &.DateInput_input__focused {
      border-bottom: solid 1px ${constants.COLORS.primary};
    }
  }
  .CalendarDay__selected_span {
    background: ${constants.COLORS.secondary};
    border: 1px double ${constants.COLORS.secondary};
    color: #fff;
  }
  .CalendarDay__selected {
    background:  ${constants.COLORS.primary};
    border: 1px double  ${constants.COLORS.primary};
    color: white;
  }
  .CalendarDay__selected:hover {
    background:  ${constants.COLORS.primary};
    color: white;
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${constants.COLORS.secondary};
    border: 1px double ${constants.COLORS.secondary};
    color: #fff;
  }
`

export default dateRangePickerCustomStyle
