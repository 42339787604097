import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class GuideService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async createOrUpdateGuideColabStatus(params) {
    const { data } = await this.httpClient.post(`/guide/api/guide_colabs/create_or_update`, {
      params,
    })
    const updatedStatus = data.new_status // this.serializer.parseResourceData(data)

    return { updatedStatus, flush: data.flush }
  }

  public async getContracts(params) {
    const { data } = await this.httpClient.get(`/guide/api/contracts/`, {
      params,
    })
    return { contractsData: data.contracts }
  }

  public async tourGuideApprove(id) {
    const { data } = await this.httpClient.put(`/guide/api/sound_outs/${id}/approve`)
    return {
      tourGuide: data.tour_guide,
      tourGuideMessage: data.tour_guide_message,
      flush: data.flush,
    }
  }

  public async tourGuideDecline(id) {
    const { data } = await this.httpClient.put(`/guide/api/sound_outs/${id}/decline`)
    return {
      tourGuide: data.tour_guide,
      tourGuideMessage: data.tour_guide_message,
      flush: data.flush,
    }
  }
}

export default GuideService
