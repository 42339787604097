import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import Required from './required'

interface IProps {
  label: string
  required?: boolean
}

const InputLabel: React.FC<IProps> = props => {
  return (
    <InputLabelWrapper>
      {props.label}
      {props.required && <Required />}
    </InputLabelWrapper>
  )
}

const InputLabelWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 180px;
  padding: 8px 12px 8px 0;

  font-size: 14px;
  font-weight: bold;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding-right: 0;
    align-items: baseline;
    width: 100%;
    // margin-bottom: 12px;
  }
`

export default InputLabel
