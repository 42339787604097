import I18n from 'core/i18n'
import * as React from 'react'
import { utilService } from '../../core/services'
import * as constants from '../../static/constants'

import styled from 'styled-components'
import { BREAKPOINT_TABLET_SMALL } from '../../static/constants'
import RoleLabel from 'components/atoms/RoleLabel'
import LinkButton from 'components/atoms/LinkButton'
import { IsMobile, IsTabletOrDesktop } from 'utils/responsive'
import { Score } from 'components/atoms'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import SVG from 'react-inlinesvg'

SwiperCore.use([Navigation])

const GuideCard = ({ user }) => (
  <div className="Guide">
    <div className="info">
      <div className="avator">
        <img alt="" src={user.avatar_url} aria-hidden="true" />
      </div>
      <div className="profile">
        <div className="name">{user.username}</div>
        <RoleLabel user={user} />
      </div>
    </div>
    <div className="profileFooter">
      <div className="profileScore">
        <IsTabletOrDesktop>
          <SVG
            src="/images/icons/star.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
          <div className="avarage">{user.average_review}</div>
        </IsTabletOrDesktop>

        <IsMobile>
          <Score score={user.average_review} />
        </IsMobile>
        <div className="count">({I18n.t('review.review_count', { count: user.review_count })})</div>
      </div>
    </div>
    <div className="bio">
      <p>{user.bio}</p>
    </div>

    <LinkButton href={`/user/${user.username}-${user.id}`} miniLink={true}>
      <div className="show-more">
        <span>{I18n.t('generic.show_more')}</span>
        <SVG
          src="/images/icons/arrow_forward.svg"
          className="icon"
          aria-hidden="true"
          role="presentation"
        />
      </div>
    </LinkButton>
  </div>
)

const Guides = props => {
  const { data: users } = utilService.getDataFromJson(props.users)

  React.useEffect(() => {
    const guidesLS = document.getElementById('guides-ls')
    guidesLS.style.display = 'none'
  })

  return (
    <Wrapper>
      <Swiper
        slidesPerView={'auto'}
        slidesPerGroup={1}
        spaceBetween={20}
        rewind={true}
        navigation={{
          nextEl: '.button-next',
          prevEl: '.button-prev',
        }}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        breakpoints={{
          720: {
            slidesPerView: 2,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            rewind: true,
          },
          960: {
            slidesPerView: 3,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            rewind: true,
          },
          1200: {
            slidesPerView: 4,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            rewind: true,
          },
        }}
      >
        {users.map(user => (
          <SwiperSlide key={user.id}>
            <GuideCard user={user} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="NavContainer">
        <button type="button" className="button-prev" aria-label="Prev Image">
          <SVG
            src="/images/icons/keyboard_arrow_left.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </button>
        <button type="button" className="button-next" aria-label="Next Image">
          <SVG
            src="/images/icons/keyboard_arrow_right.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  .swiper-slide {
    height: auto;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 240px;
    }
    > div {
      height: 100%;
    }
  }

  .Guide {
    width: 100%;
    padding: 24px;
    border: solid 1px ${constants.COLORS.tertiary};
    border-radius: 10px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      padding: 16px;
    }
    > a {
      color: ${constants.COLORS.black};
    }
  }

  .avator {
    display: inline-block;

    > img {
      width: 70px;
      height: 70px;
      background-color: ${constants.COLORS.cardBG};
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        width: 48px;
        height: 48px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin-bottom: 8px;
    }
  }
  .profile {
    display: inline-block;
    width: calc(100% - 80px);
    padding-left: 16px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: calc(100% - 48px);
      padding-left: 8px;
    }
  }

  .name {
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 8px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      font-size: 14px;
    }
  }
  .profileFooter {
    display: flex;
    align-items: center;
  }

  .profileScore {
    display: flex;
    align-items: center;
    line-height: 24px;

    .icon {
      color: ${constants.COLORS.secondary};
      width: 22px;
      height: 22px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        width: 18px;
        height: 18px;
      }
    }

    .avarage {
      margin-left: 4px;
      font-size: 14px;
      font-weight: bold;
    }

    .count {
      margin-left: 4px;
      font-size: 14px;
    }
  }

  .bio {
    margin: 8px 0;

    > p {
      max-height: 80px;
      line-height: 20px;
      font-size: 14px;
      white-space: pre-wrap;
      overflow: auto;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        max-height: 60px;
      }
    }
  }
  .show-more {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    .icon {
      margin-left: 8px;
      width: 18px;
      height: 18px;
    }
  }

  .NavContainer {
    position: absolute;
    top: -52px;
    right: 0;
    display: flex;
    align-items: center;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: none;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0;
      background: ${constants.COLORS.white};
      border: 1px solid ${constants.COLORS.divider};
      border-radius: 50%;
      appearance: none;
      cursor: pointer;
      transition: border-color 0.2s;
      .icon {
        color: ${constants.COLORS.primary};
      }
      & + button {
        margin-left: 10px;
      }
      &:hover {
        border-color: ${constants.COLORS.primary};
      }
    }
  }
`

export default Guides
