import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  id?: string
  handleClick?: () => void
  rounded?: boolean
  zeroRounded?: boolean
  primary?: boolean
  small?: boolean
  disabled?: boolean
  textColor?: string
  backgroundColor?: string
  borderColor?: string
  fullWidth?: boolean
  noMargin?: boolean
  link?: boolean
  noWrap?: boolean
  fake?: boolean
}

const Button: React.FC<IProps> = props => {
  return (
    <ButtonWrapper
      className="Button"
      type={props.primary ? 'submit' : 'button'}
      onClick={props.handleClick || undefined}
      disabled={props.disabled}
      {...props}
    >
      {props.children}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button`
  width: ${(props: IProps) => (props.fullWidth ? '100%' : 'auto')};
  height: 40px;
  padding: 0 16px;
  border-radius: ${(props: IProps) => (props.zeroRounded ? '0px' : props.rounded ? '20px' : '6px')};
  outline: none;
  cursor: pointer;
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  white-space: ${(props: IProps) => (props.noWrap ? 'wrap' : 'nowrap')};
  ${({ fake }) => fake && 'pointer-events: none;'}
  transition: opacity .2s;
  &:hover {
    opacity: .6;
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    text-decoration: none;
    color: ${constants.COLORS.primary};
  }

  ${(props: IProps) =>
    props.small &&
    `
    height: 32px;
    font-size: 12px;
    padding: 0 8px;
  `}

  ${(props: IProps) =>
    props.primary
      ? `
    background-color: ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
    border: none;

    > a {
      color: ${constants.COLORS.white};
    }
  `
      : `
    background-color: ${constants.COLORS.white};
    color: ${constants.COLORS.primary};
    border: solid 1px ${constants.COLORS.primary};
  `}

  ${(props: IProps) =>
    props.disabled &&
    `
    background-color: ${constants.COLORS.disabled};
    color: ${constants.COLORS.subText};
    border: none;
  `}
  ${(props: IProps) =>
    props.link &&
    `
    font-size: 12px;
    color: ${constants.COLORS.subText};
    border: solid 1px ${constants.COLORS.divider};
  `}

  ${(props: IProps) =>
    props.textColor &&
    `
    color: ${props.textColor};

    > a {
      color: ${props.textColor};
    }
  `}

  ${(props: IProps) =>
    props.backgroundColor &&
    `
    background-color: ${props.backgroundColor};
    border-color: ${props.backgroundColor};
  `}

${(props: IProps) =>
  props.borderColor &&
  `
    border-color: ${props.borderColor};
  `}
  & + & {
    margin-left: ${(props: IProps) => (props.noMargin ? 0 : '10px')};
  }
`

export default Button
