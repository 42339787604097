import { Tag } from 'components/atoms'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  tags: any
  inPostCard?: boolean
}

const Tags: React.VFC<IProps> = ({ tags, inPostCard }) => {
  const filterdTags = React.useMemo(() => {
    return tags.filter((_, i) => !inPostCard || i < 3)
  }, [inPostCard, tags])
  return (
    <TagsWrapper inPostCard={inPostCard}>
      {filterdTags.map(tag => (
        <Tag key={tag.id} tag={tag} />
      ))}
    </TagsWrapper>
  )
}

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  ${({ inPostCard }) => {
    return (
      inPostCard &&
      `
        position: absolute;
        top: 0;
        left: 0;
        padding: 12px 16px;
        @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
          padding: 8px 12px;
        }
      `
    )
  }}
`

export default Tags
