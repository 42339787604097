import cookie from 'cookie'
import { get } from 'lodash'
import * as React from 'react'
import { COLORS } from 'static/constants'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import SVG from 'react-inlinesvg'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}
declare var window: IWindow

export default class LocaleSelect extends React.PureComponent<{}, {}> {
  public lang: any
  private locale: any
  private cookie: any
  private browserLanguage: any

  constructor(props) {
    super(props)
    this.cookie = cookie.parse(document.cookie)
    this.locale = I18n.locale
    this.browserLanguage =
      (get(window, 'navigator.languages') && get(window, 'navigator.languages[0]')) ||
      get(window, 'navigator.language') ||
      get(window, 'navigator.userLanguage') ||
      get(window, 'navigator.browserLanguage')
  }

  public changeLocale = locale => {
    if (window.localePath) {
      window.location.href = window.localePath[locale]
    }
  }

  public render() {
    return (
      <LocaleSelectWrapper className="LocaleSelect">
        <select
          defaultValue={`${this.cookie.locale || this.browserLanguage}_path`}
          onChange={event => {
            this.changeLocale(event.target.value)
          }}
          aria-label="Language select"
        >
          <option value="en_path">English</option>
          <option value="ja_path">日本語</option>
        </select>
        <SVG
          src="/images/icons/keyboard_arrow_down.svg"
          className="icon"
          aria-hidden="true"
          role="presentation"
        />
      </LocaleSelectWrapper>
    )
  }
}
const LocaleSelectWrapper = styled.div`
  position: relative;
  display: flex;
  transition: width 0.25s ease-in-out;
  align-items: center;
  color: ${COLORS.white};
  transition: opacity .2s;
  &:hover {
    opacity: .75;
  }
  > select {
    position: relative;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 4px 24px 4px 4px;
    background: none transparent;
    vertical-align: middle;
    color: inherit;
    box-sizing: content-box;
    outline: none;
    cursor: pointer;
    z-index: 1;

    > option {
      color: ${COLORS.white};
      background: ${COLORS.black};
      &:hover:  ${COLORS.primary};
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 0;
  }
`
