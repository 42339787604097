import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import SVG from 'react-inlinesvg'

interface ISearchProps {
  defaultKeywords: string
  onSearchHandler: React.FocusEventHandler<HTMLInputElement>
  onKeyDownHandler: React.KeyboardEventHandler<HTMLInputElement>
}

interface IMobileSearch extends ISearchProps {
  setSearchBoxIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  showFilterIcon: boolean
}

export const DesktopSearch: React.VFC<ISearchProps> = ({
  defaultKeywords,
  onSearchHandler,
  onKeyDownHandler,
}) => (
  <Search className="Search">
    <div className="Search_Input _keywords">
      <input
        name="keywords"
        type="text"
        placeholder={I18n.t('generic.keyword')}
        defaultValue={defaultKeywords}
        onBlur={onSearchHandler}
        onKeyDown={onKeyDownHandler}
      />
      <SVG src="/images/icons/search.svg" className="icon" aria-hidden="true" role="presentation" />
    </div>
  </Search>
)

const showFilter = () => {
  const filters = document.getElementsByClassName('SearchHeader')
  if (filters[0].classList.contains('showFilter')) {
    filters[0].classList.remove('showFilter')
  } else {
    filters[0].classList.add('showFilter')
  }
}

export const MobileSearch: React.VFC<IMobileSearch> = ({
  defaultKeywords,
  onSearchHandler,
  onKeyDownHandler,
  setSearchBoxIsOpen,
  showFilterIcon,
}) => (
  <SearchBox className="SearchBox">
    <div className="SearchBox_Cancel" onClick={() => setSearchBoxIsOpen(false)}>
      <SVG src="/images/icons/close.svg" className="icon" aria-hidden="true" role="presentation" />
    </div>
    <div className="SearchBox_Box">
      <input
        name="keywords"
        type="text"
        placeholder="Keyword"
        defaultValue={defaultKeywords}
        onBlur={onSearchHandler}
        onKeyDown={onKeyDownHandler}
      />
      <SVG
        src="/images/icons/search.svg"
        className="icon search"
        aria-hidden="true"
        role="presentation"
      />
    </div>
    {showFilterIcon && (
      <a className="SearchBox_Tune" onClick={() => showFilter()}>
        <span className="search_tune">絞り込み</span>
        <SVG
          src="/images/icons/tune.svg"
          className="icon tune"
          aria-hidden="true"
          role="presentation"
        />
      </a>
    )}
  </SearchBox>
)

const SearchBox = styled.div`
  background-color: ${constants.COLORS.white};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  padding: 0 10px;
  align-items: center;
  z-index: 9100;

  .SearchBox_Cancel {
    width: 30px;
    font-size: 14px;
    font-weight: bold;
    color: ${constants.COLORS.subText};
    cursor: pointer;
    .close {
      padding-top: 5px;
    }
  }
  .SearchBox_Box {
    flex: 1;
    position: relative;
    input {
      display: block;
      width: 100%;
      height: 32px;
      padding: 10px 14px;
      border: solid 1px ${constants.COLORS.divider};
      border-radius: 20px;
      font-size: 15px;
      transition: border 0.2s ease;
      outline: none;
      background: none;

      &::placeholder {
        font-weight: 700;
        color: ${constants.COLORS.divider};
      }

      &:focus {
        border: solid 1px ${constants.COLORS.primary};
      }
    }
    .search {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto;
      width: 24px;
      height: 24px;
    }
  }
  .SearchBox_Tune {
    display: flex;
    align-items: center;
    @media (min-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      display: none;
    }
    .search_tune {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 15px;
      color: ${constants.COLORS.subText};
      margin-left: 2em;
    }
    .tune {
      color: ${constants.COLORS.subText};
    }
  }
`

const Search = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 264px;
  padding: 0 32px;

  .Search_Input {
    position: relative;
    width: 100%;

    > .icon {
      position: absolute;
      top: 50%;
      left: 8px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
    }

    > input {
      width: 100%;
      display: block;
      height: 36px;
      padding: 8px 12px 8px 36px;
      border: solid 1px ${constants.COLORS.divider};
      border-radius: 18px;
      font-size: 16px;
      transition: border-color 0.2s ease;
      outline: none;
      background: none;

      &:focus {
        border-color: ${constants.COLORS.primary};
      }
      @media (max-width: ${constants.BREAKPOINT_DESKTOP_XXL}px) {
        font-size: 14px;
      }
    }
  }
`
