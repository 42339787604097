import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  children: React.ReactNode
  type?: 'default' | 'subLink' | 'link' | 'bold'
  maxWidth?: number
}

const EditTableColumn: React.VFC<IProps> = ({ children, maxWidth = 300, type = 'default' }) => {
  return (
    <Column className={type} maxWidth={maxWidth}>
      {children}
    </Column>
  )
}

const Column = styled.div`
  max-width: ${({ maxWidth }) => maxWidth}px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    white-space: pre-wrap;
  }
  &.subLink,
  &.link {
    color: ${constants.COLORS.primary};
    a {
      color: ${constants.COLORS.primary};
    }
  }

  &.subLink,
  &.bold {
    font-size: 14px;
  }
  &.link,
  &.bold {
    font-weight: 700;
  }
  &.link {
    font-size: 16px;
  }
`

export default EditTableColumn
