import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import ClassNames from 'classnames'
import SVG from 'react-inlinesvg'

interface IProps {
  iconName: string
  href: string | null | undefined
  size?: number
  label?: any
}

const IconLink: React.VFC<IProps> = ({ href, iconName = 'link', size, label }) => {
  return (
    <IconLinkWrap
      className={ClassNames(href ? '' : 'disabled', label && 'withLabel')}
      href={href}
      target="_blank"
      size={size}
    >
      <div className="IconBody">
        <SVG
          src={`/images/icons/${iconName}.svg`}
          className="icon"
          aria-hidden="true"
          role="presentation"
        />
      </div>
      {label && <span className="label">{label}</span>}
    </IconLinkWrap>
  )
}

const IconLinkWrap = styled.a`
  color: ${constants.COLORS.primary};
  .IconBody {
    width: ${({ size }: IProps) => `${size ?? 24}px`};
    height: ${({ size }: IProps) => `${size ?? 24}px`};
    .icon {
      width: ${({ size }: IProps) => `${size ?? 24}px`};
      height: ${({ size }: IProps) => `${size ?? 24}px`};
    }
  }
  &:hover {
    opacity: 0.8;
  }
  &.disabled {
    color: ${constants.COLORS.disabled};
    cursor: not-allowed;
  }
  &.withLabel {
    display: flex;
    align-items: center;
    .label {
      margin-left: 4px;
      font-size: 12px;
      white-space: nowrap;
    }
  }
`

export default IconLink
