import * as constants from 'static/constants'
import * as React from 'react'
import styled from 'styled-components'

import MediaQuery from 'react-responsive'
import { IJsonResponse } from '../../core/JsonApiSerializer'
import { utilService } from '../../core/services'
import { BREAKPOINT_TABLET_LARGE, BREAKPOINT_TABLET_MOBILE } from 'static/constants'
import { IsMobile, IsTabletOrDesktop } from 'utils/responsive'
import I18n from 'core/i18n'

interface IAreaListProps {
  areas: IJsonResponse
  locale: string
}
interface IAreaThumbnail {
  area: any
  locale: string
}
const AreaImage: React.VFC<{ src: string }> = ({ src }) => (
  <img
    src={src}
    onError={e => {
      e.currentTarget.onerror = null
      e.currentTarget.src = `/images/no-image.svg`
      e.currentTarget.className = 'noImage'
    }}
    alt=""
    loading="lazy"
  />
)

const AreaThumbnail: React.VFC<IAreaThumbnail> = ({ area, locale }) => {
  return (
    <SliderItem>
      <a className="panelItem" href={`/posts/search?area_ids[]=${area.id}`}>
        <AreaImage src={area.image_url || null} />
        <div className="overray" />
        <span>{locale === 'ja' && area.name_ja ? area.name_ja : area.name}</span>
      </a>
    </SliderItem>
  )
}

const AreaItem: React.VFC<IAreaThumbnail> = ({ area, locale }) => {
  return (
    <a className="Area_Card" href={`/posts/search?area_ids[]=${area.id}`}>
      <AreaImage src={area.image_url || null} />
      <span>{locale === 'ja' && area.name_ja ? area.name_ja : area.name}</span>
    </a>
  )
}

const AreaList: React.FC<IAreaListProps> = props => {
  const { data: areas } = utilService.getDataFromJson(props.areas)
  return (
    <AreaListWrapper>
      <IsTabletOrDesktop>
        <div className="Slider">
          <MediaQuery query={`(max-width: ${BREAKPOINT_TABLET_LARGE}px)`}>
            {/*
            {matches => (
              {areas.map((area, index) => (
                <AreaThumbnail key={index} area={area} locale={props.locale} />
              ))}
            )}
            */}
          </MediaQuery>
        </div>
      </IsTabletOrDesktop>
      <IsMobile>
        <h2 className="AreaTitle">{I18n.t('search_pan.title_japan_tour')}</h2>
        <div className="Areas">
          {areas.map((area, index) => (
            <AreaItem key={index} area={area} locale={props.locale} />
          ))}
        </div>
      </IsMobile>
    </AreaListWrapper>
  )
}
const SliderItem = styled.div`
  width: 100%;

  .panelItem {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 200px;
    color: ${constants.COLORS.white};

    > img {
      position: absolute;
      width: 100%;
      height: 200px;
      object-fit: cover;
      overflow: hidden;
      border-radius: 8px;
    }

    .noImage {
      position: absolute;
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      background-color: ${constants.COLORS.white};
    }
    .overray {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: ${constants.COLORS.blackOverray};
      border-radius: 8px;
    }
    > span {
      display: block;
      font-weight: bold;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      z-index: 5;

      @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        bottom: 24px;
        font-size: 16px;
      }
    }
  }
`

const AreaListWrapper = styled.div`
  padding-bottom: 108px;
  background: ${constants.COLORS.white};
  @media screen and (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding-bottom: 8px;
  }
  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: 240px;
    padding-top: 24px;
  }
  .AreaTitle {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .Areas {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 32px;
    height: 160px;
    padding: 16px;
    overflow: auto;
    .Area_Card {
      display: flex;
      align-items: center;
      width: 130px;
      height: 50px;
      > img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
      > span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 14px;
        font-weight: 700;
        margin-left: 16px;
        color: ${constants.COLORS.black};
      }
    }
  }
`

export default AreaList
