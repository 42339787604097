import I18n from 'core/i18n'
import dompurify from 'dompurify'
import moment from 'moment'
import { TourGuideRequestStatus } from 'static/interfaces'

export function isTouchDevice() {
  const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  return window.navigator.userAgent.search(regexp) !== -1
}

export function getStripePublicKey(): any {
  const element: any = document.querySelector('meta[name=stripe-public-key]')

  return element ? element.content : ''
}

export function withHtmlTag(text: string): string {
  const withLinkText = dompurify.sanitize(text, {
    ALLOWED_TAGS: ['a', 'span', 'img'],
    ALLOWED_ATTR: ['href', 'target', 'class', 'src'],
  })
  return withLinkText
}

export const getSlidesToShow = (length: number, max: number) => {
  return length < max ? length : max
}

// 管理者でもエージェントでもオーナーでもないユーザーのみお気に入りが可能
export const checkLikeable = (post, user): boolean => {
  if (!user || !post) {
    return false
  }
  const { admin, agent, id } = user
  const { is_owner, user: post_user } = post

  return !admin && !agent && (!is_owner || post_user?.id !== id)
}

const getMoment = (locale, dateStr, format) => {
  if (locale === 'ja') {
    return moment(dateStr, format)
  }
  return moment(dateStr)
}

export const getFormattedFromDate = (locale, dateSrting, format) => {
  return getMoment(locale, dateSrting, 'YYYYMMDD').format(format)
}

export const getFormattedFromDateTime = (locale, dateTimeSrting, format) => {
  return getMoment(locale, dateTimeSrting, 'YYYYMMDD HH:mm').format(format)
}

export const getTourGuideStatusLabel = (
  requestStatus: TourGuideRequestStatus = TourGuideRequestStatus.nothing
) => {
  return I18n.t(`tour_guide_request_status.${requestStatus}`)
}

export const getStatusBadgeTypeFromStatus = (
  status: TourGuideRequestStatus | 'requested' | 'approved' | 'declined'
) => {
  if (
    !status ||
    status === TourGuideRequestStatus.nothing ||
    status === TourGuideRequestStatus.canceled
  ) {
    return 'empty'
  }
  if (status === TourGuideRequestStatus.soundouted) {
    return 'usually'
  }
  if (status === TourGuideRequestStatus.approved) {
    return 'success'
  }
  if (status === TourGuideRequestStatus.declined) {
    return 'caution'
  }
  return 'info'
}
