import * as React from 'react'
import { COLORS } from 'static/constants'
import styled from 'styled-components'

interface IProps {
  href?: string
  children?: React.ReactChild
  fillBG?: boolean
  miniLink?: boolean
  showBorder?: boolean
  fullWidth?: boolean
  newTab?: boolean
  alignLeft?: boolean
}

const LinkButton: React.VFC<IProps> = ({
  href,
  children,
  fillBG,
  miniLink,
  showBorder,
  fullWidth,
  newTab,
  alignLeft,
}) => {
  return (
    <Link
      mini={miniLink}
      fullWidth={fullWidth}
      showBorder={showBorder}
      fillBG={fillBG}
      alignLeft={alignLeft}
    >
      <a href={href} target={newTab ? '_blank' : '_self'}>
        {children}
      </a>
    </Link>
  )
}

const Link = styled.button`
  outline: none;
  font-weight: 700;
  height: 30px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 20px;
  background: ${props => (props.fillBG ? COLORS.white : 'transparent')};
  padding: ${props => (props.showBorder ? '0 16px' : 0)};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  color: ${props => (props.mini ? `${COLORS.subText}` : `${COLORS.primary}`)};
  border: ${props =>
    props.showBorder ? `solid 1px ${props.mini ? COLORS.divider : COLORS.primary}` : 'none'};

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    text-decoration: none;
    color: inherit;
    transition: opacity 0.2s;
    text-align: ${props => (props.alignLeft ? 'left' : 'center')};
    > .icon {
      margin-left: 4px;
      width: 20px;
      height: 20px;
    }
    &:hover {
      opacity: 0.75;
    }
  }
`
export default LinkButton
