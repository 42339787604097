import * as React from 'react'
import { COLORS } from 'static/constants'
import styled from 'styled-components'

const ListItem: React.VFC<{ children: React.ReactNode; separate?: boolean }> = ({
  children,
  separate,
}) => {
  return <ListItemWrap separate={separate}>{children}</ListItemWrap>
}

const ListItemWrap = styled.div`
  ${({ separate }) =>
    separate &&
    `
    border-top: 1px solid ${COLORS.divider};
    padding-top: 16px;
  `}

  & + & {
    margin-top: 16px;
  }
`

export default ListItem
