import ClassNames from 'classnames'
import * as React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import { IsDesktopXL, IsDesktopSmall, IsMobile, IsTabletOrDesktop } from '../../utils/responsive'
import Button from '../atoms/Button'
import HeaderMenu from '../atoms/HeaderMenu'
import { DesktopSearch, MobileSearch } from '../atoms/HeaderSearch'
import HeaderNotifications from 'components/atoms/HeaderNotifications'
import SVG from 'react-inlinesvg'

import { IUser } from '../../static/interfaces'
import { getInquiryLink } from 'utils/locale'
import { LocaleSelect } from 'components/atoms'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}
declare var window: IWindow

interface IProps {
  isSignedIn: boolean
  user: IUser
  lang?: { [key: string]: string }
  top?: boolean
}
const getRoleLabel = user => {
  if (user.admin) {
    return I18n.t('generic.administrator')
  }
  if (user.agent) {
    return I18n.t('generic.agent')
  }
  if (user.guide) {
    return I18n.t('generic.guide')
  }
  if (user.guest) {
    return I18n.t('generic.guest')
  }
  return ''
}

const ENTER_KEYCODE = 13

interface INotification {
  id: number
  title: string
  created_at: string
  read_at: string
  url: string
}

const Header: React.FC<IProps> = props => {
  const [searchBoxIsOpen, setSearchBoxIsOpen] = React.useState(false)
  const [showFilterIcon, setShowFilterIcon] = React.useState(false)
  const { isSignedIn, user } = props

  const urlParams = new URLSearchParams(window.location.search)
  const defaultKeywords = urlParams.get('keywords') || ''

  const inquiryLink = React.useMemo(() => {
    return getInquiryLink(I18n.locale)
  }, [])

  const onSearchHandler = async event => {
    const currentUrlParams = new URLSearchParams(window.location.search)

    if (event.target.value) {
      currentUrlParams.set(event.target.name, event.target.value)
    } else {
      currentUrlParams.delete(event.target.name)
    }

    location.href = `/posts/search?${currentUrlParams.toString()}`
  }

  const onKeyDownHandler = event => {
    if (event.keyCode && event.keyCode === ENTER_KEYCODE) {
      onSearchHandler(event)
    }
  }

  React.useEffect(() => {
    if (props.lang) {
      window.localePath = { ...props.lang }
    }
  }, [])

  React.useEffect(() => {
    if (window.location.pathname === '/posts/search') {
      setShowFilterIcon(true)
    }
  }, [])

  React.useEffect(() => {
    if (document.body.classList.contains('is-loading')) {
      const loading = document.getElementById('header-ls')
      loading.style.display = 'none'
      document.body.classList.remove('is-loading')
    }
  })

  return (
    <HeaderWrapper className={ClassNames('Header', { top: props.top })}>
      <GlobalStyle top={props.top} />
      <div className="Header_Inner">
        <div className="Header_Left">
          {isSignedIn ? (
            <a className="Logo" href={user?.admin ? '/admin/posts' : '/'}>
              <IsTabletOrDesktop>
                <img
                  src="/images/logo.svg"
                  alt={I18n.t('admin.settings.default_site')}
                  className="LogoFull"
                />
              </IsTabletOrDesktop>
              <IsMobile>
                <img
                  src="/images/logo_symbol.svg"
                  alt={I18n.t('admin.settings.default_site')}
                  className="LogoSymbol"
                />
              </IsMobile>
            </a>
          ) : (
            <a className="Logo" href={user?.admin ? '/admin/posts' : '/'}>
              <img
                src="/images/logo.svg"
                alt={I18n.t('admin.settings.default_site')}
                className="LogoFull"
              />
            </a>
          )}
          <IsDesktopXL>
            <DesktopSearch
              defaultKeywords={defaultKeywords}
              onSearchHandler={onSearchHandler}
              onKeyDownHandler={onKeyDownHandler}
            />
          </IsDesktopXL>
        </div>
        {isSignedIn ? (
          <div className="Header_Items">
            <IsDesktopXL>
              {/* <div className="Header_LocaleSelect">
                <LocaleSelect />
              </div> */}
              <HeaderNotifications unreadNotifications={props.user.unread_notifications_count} />
              {!user.admin && (
                <Messages>
                  <a href="/conversations">
                    <SVG
                      src="/images/icons/message.svg"
                      className="icon"
                      aria-hidden="true"
                      role="presentation"
                    />
                  </a>
                </Messages>
              )}
              <HeaderMenu
                user={props.user}
                lang={props.lang}
                isSignedIn={isSignedIn}
                desktop={true}
              />
            </IsDesktopXL>
            <IsDesktopSmall>
              {!props.top && (
                <div className="SearchToggle" onClick={() => setSearchBoxIsOpen(true)}>
                  <SVG
                    src="/images/icons/search.svg"
                    className="icon"
                    aria-hidden="true"
                    role="presentation"
                  />
                </div>
              )}
              <HeaderNotifications unreadNotifications={props.user.unread_notifications_count} />
              {!user.admin && (
                <Messages>
                  <a href="/conversations">
                    <SVG
                      src="/images/icons/message.svg"
                      className="icon"
                      aria-hidden="true"
                      role="presentation"
                    />
                  </a>
                </Messages>
              )}
              <HeaderMenu
                user={props.user}
                lang={props.lang}
                isSignedIn={isSignedIn}
                mobile={true}
              />

              {searchBoxIsOpen && (
                <MobileSearch
                  defaultKeywords={defaultKeywords}
                  onSearchHandler={onSearchHandler}
                  onKeyDownHandler={onKeyDownHandler}
                  setSearchBoxIsOpen={setSearchBoxIsOpen}
                  showFilterIcon={showFilterIcon}
                />
              )}
            </IsDesktopSmall>
          </div>
        ) : (
          <>
            <div className="Header_Items">
              <IsDesktopXL>
                <Buttons>
                  <LocaleSelect />
                  <a href="https://blog.japanwondertravel.com/" target="blank">
                    {I18n.t('top_nav.blog')}
                  </a>
                  <a href={inquiryLink} target="blank">
                    {I18n.t('top_nav.inquiry')}
                  </a>
                  <Button primary={true} small={false}>
                    <a href="/users/sign_in">{I18n.t('top_nav.login')}</a>
                  </Button>
                  <a href="/users/sign_up">{I18n.t('top_nav.signup_guest')}</a>
                </Buttons>
              </IsDesktopXL>
              <IsDesktopSmall>
                <HeaderMenu
                  user={props.user}
                  lang={props.lang}
                  isSignedIn={isSignedIn}
                  mobile={true}
                />
              </IsDesktopSmall>
            </div>
          </>
        )}
      </div>
    </HeaderWrapper>
  )
}

const GlobalStyle = createGlobalStyle`
  main {
    min-height: calc(100vh - ${constants.HEADER_HEIGHT + constants.FOOTER_HEIGHT}px);
    //padding-top: ${constants.HEADER_HEIGHT}px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
      min-height: calc(100vh - ${constants.MOBILE_HEADER_HEIGHT + constants.FOOTER_HEIGHT}px);
      //padding-top: ${constants.MOBILE_HEADER_HEIGHT}px;
      background: ${constants.COLORS.white}
    }
  }
`

const Messages = styled.div`
  cursor: pointer;

  a {
    width: 40px;
    height: 40px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 16px;
    transition: background-color 0.2s;
    &:hover {
      background-color: ${constants.COLORS.cardBG};
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      color: ${constants.COLORS.black};
      &.open {
        color: ${constants.COLORS.secondary};
      }
    }
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    color: ${constants.COLORS.primary};
    margin: 0 12px;
    transition: opacity 0.2s;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XXL}px) {
      font-size: 14px;
      margin: 0 8px;
    }
    &:hover {
      opacity: 0.75;
    }
  }
  .LocaleSelect {
    margin-right: 12px;
    color: ${constants.COLORS.primary};
    > select {
      > option {
        color: ${constants.COLORS.primary};
      }
    }
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XXL}px) {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  button.Button {
    flex-shrink: 0;
    height: auto;
    line-height: 20px;
    padding: 8px 24px;
    margin: 0 12px;
    font-size: 16px;
    font-weight: normal;
    border-radius: 4px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XXL}px) {
      font-size: 14px;
      margin: 0 8px;
    }
  }
`

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${constants.HEADER_HEIGHT}px;
  padding: 0px 80px;
  background-color: ${constants.COLORS.white};
  z-index: 5000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
    height: ${constants.MOBILE_HEADER_HEIGHT}px;
  }
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    padding: 0 50px;
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL - 1}px) {
    padding: 0;
  }

  .SearchToggle {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &.top {
    height: ${constants.HEADER_HEIGHT}px;
    .Header_Inner {
      display: flex;
      height: 100%;
    }
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
      height: ${constants.MOBILE_HEADER_HEIGHT}px;
    }
  }

  .Header_Inner {
    display: flex;
    height: ${constants.HEADER_HEIGHT}px;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
      height: ${constants.MOBILE_HEADER_HEIGHT}px;
    }
  }

  .Header_Left {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .Logo {
    flex-shrink: 0;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL - 1}px) {
      margin-left: 16px;
    }

    > img {
      display: block;
      height: auto;
      &.LogoFull {
        width: 300px;
        aspect-ratio: 389/64;
        @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
          width: 260px;
        }
      }
      &.LogoSymbol {
        width: 44px;
      }
    }
  }

  .Header_Items {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .burger {
      margin-left: 8px;
    }
  }
`

export default Header
