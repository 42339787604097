import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class SettingsService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async createIdentificationImage(image) {
    const formData = new FormData()
    formData.append('identification_image[image]', image)

    const {
      data: { identifcation, flush },
    } = await this.httpClient.post(`/api/identification_images`, formData)
    const createdIdentificationImage = this.serializer.parseResourceData(
      identifcation,
      identifcation.data
    )

    return { createdIdentificationImage, flush }
  }

  public async updateNotification(formData) {
    const {
      data: { user, flush },
    } = await this.httpClient.patch('/settings/notification', formData)

    return { user, flush }
  }

  public async deleteSocialProfile(provider) {
    const {
      data: { user, flush },
    } = await this.httpClient.delete(`/api/social_profiles/${provider}`)

    return { user, flush }
  }

  public async updateSetting(setting) {
    const { settings, flush } = await this.httpClient.patch(`/admin/api/setting`, setting)

    return { settings, flush }
  }

  public async getCard() {
    // cardをjson apiのレスポンスに
    const { data } = await this.httpClient.get('/api/card')

    return { card: data }
  }

  public async createCard(stripeToken) {
    // cardをjson apiのレスポンスに
    const {
      data: { card, flush },
    } = await this.httpClient.post('/api/card', { stripeToken })

    return { card, flush }
  }

  public async createGuideApplication() {
    const {
      data: { guide_applications, flush },
    } = await this.httpClient.post('/api/guide_applications')

    return { guide_applications, flush }
  }

  public async createAgentApplication() {
    const {
      data: { agent_applications, flush },
    } = await this.httpClient.post('/api/agent_applications')

    return { agent_applications, flush }
  }

  public async requestingGuideRellation(params) {
    const {
      data: { guide_relation, flush },
    } = await this.httpClient.post('/api/guide_relation/requesting', params)

    return { guide_relation, flush }
  }

  public async requestingGuideDirectRelation(params) {
    const {
      data: { guide_relation, flush },
    } = await this.httpClient.post('/api/guide_relation/direct_requesting', params)

    return { guide_relation, flush }
  }

  public async cancelGuideRellation(params) {
    const {
      data: { guide_relation, flush },
    } = await this.httpClient.put('/api/guide_relation/cancel', params)

    return { guide_relation, flush }
  }

  public async approveAgentRelation(params) {
    const {
      data: { guide_relation, flush },
    } = await this.httpClient.put('/api/agent_relation/approve', params)

    return { guide_relation, flush }
  }

  public async declineAgentRelation(params) {
    const {
      data: { guide_relation, flush },
    } = await this.httpClient.put('/api/agent_relation/decline', params)

    return { guide_relation, flush }
  }
}

export default SettingsService
