import ClassNames from 'classnames'
import { range } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import SVG from 'react-inlinesvg'

interface IProps {
  currentPage: number
  prevPage: number
  nextPage: number
  totalPages: number
  totalCount: number
  onChangePageHandler?(page: number): void
}

const PAGE_LIMIT = 3

const Pagination: React.FC<IProps> = props => {
  const currentPage = Number(props.currentPage)
  const pages = range(currentPage - 1, currentPage - 1 + PAGE_LIMIT).filter(
    page => page > 1 && page < props.totalPages
  )

  if (props.totalPages <= 1) {
    return null
  }

  return (
    <PaginationWrapper className="Pagination">
      {currentPage === 1 || props.prevPage === null ? (
        <span className="Pagination_Arrow disabled">
          <SVG
            src="/images/icons/keyboard_arrow_left.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </span>
      ) : (
        <a
          className="Pagination_Arrow _prev"
          onClick={() => props.onChangePageHandler(currentPage - 1)}
        >
          <SVG
            src="/images/icons/keyboard_arrow_left.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </a>
      )}
      <a
        className={ClassNames('Pagination_Page', { active: currentPage === 1 })}
        onClick={() => props.onChangePageHandler(1)}
      >
        {1}
      </a>
      {pages[0] > 2 && <span>...</span>}
      {pages.map(page => (
        <a
          key={page}
          className={ClassNames('Pagination_Page', { active: page === currentPage })}
          onClick={() => props.onChangePageHandler(page)}
        >
          {page}
        </a>
      ))}
      {pages[pages.length - 1] + 1 < props.totalPages && <span>...</span>}
      <a
        className={ClassNames('Pagination_Page', { active: currentPage === props.totalPages })}
        onClick={() => props.onChangePageHandler(props.totalPages)}
      >
        {props.totalPages}
      </a>
      {currentPage === props.totalPages || props.nextPage === null ? (
        <span className="Pagination_Arrow disabled">
          <SVG
            src="/images/icons/keyboard_arrow_right.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </span>
      ) : (
        <a
          className="Pagination_Arrow _next"
          onClick={() => props.onChangePageHandler(currentPage + 1)}
        >
          <SVG
            src="/images/icons/keyboard_arrow_right.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </a>
      )}
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  > span,
  > a {
    display: block;
    margin: 0 12px;
  }

  > span {
    color: ${constants.COLORS.subText};
  }

  .Pagination_Page,
  .Pagination_Arrow {
    display: block;
    margin: 0 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${constants.COLORS.primary};
    border: 1px solid ${constants.COLORS.white};
    transition: border-color.2s, background-color 0.2s, color 0.2s;
    cursor: pointer;

    > .icon {
      display: block;
    }

    &:hover {
      border-color: ${constants.COLORS.primary};
    }

    &.active {
      color: ${constants.COLORS.white};
      background-color: ${constants.COLORS.primary};
      border-color: ${constants.COLORS.primary};
    }

    &.disabled {
      color: ${constants.COLORS.disabled};
      border: 1px solid ${constants.COLORS.white};
      cursor: default;
    }
  }
`

export default Pagination
