import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostCalendarService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async get(postId, month) {
    const { data } = await this.httpClient.get(`/api/posts/${postId}/post_calendars`, {
      params: { month },
    })
    const { start_times_json, post_calendars_json, bokun_availabilities } = data
    const startTimes = bokun_availabilities
      ? []
      : this.serializer.parseResourceData(start_times_json, start_times_json.data)
    const postCalendars = bokun_availabilities
      ? []
      : this.serializer.parseResourceData(post_calendars_json, post_calendars_json.data)
    const bokunAvailabilities = bokun_availabilities?.map(ba => ba.table) || []

    return { startTimes, postCalendars, bokunAvailabilities }
  }

  public async getCalendars(postId, month) {
    const { data } = await this.httpClient.get(`/api/posts/${postId}/calendars`, {
      params: { month },
    })
    const {
      calendars: { data: calendars },
    } = data

    return { calendars }
  }

  public async getCalendarsWithPast(postId, month) {
    const { data } = await this.httpClient.get(`/api/posts/${postId}/calendars/index_for_direct`, {
      params: { month },
    })
    const {
      calendars: { data: calendars },
    } = data

    return { calendars }
  }

  public async create(params) {
    const { data } = await this.httpClient.post(`/api/post_calendars`, params)
    const { post_calendar, flush } = data
    const postCalendar = this.serializer.parseResourceData(post_calendar, post_calendar.data)

    return { postCalendar, flush }
  }

  public async update(id, params) {
    const { data } = await this.httpClient.patch(`/api/post_calendars/${id}`, params)
    const { post_calendar, flush } = data
    const postCalendar = this.serializer.parseResourceData(post_calendar, post_calendar.data)

    return { postCalendar, flush }
  }

  public async delete(id) {
    const { data } = await this.httpClient.delete(`/api/post_calendars/${id}`)
    const { flush } = data

    return { flush }
  }
}

export default PostCalendarService
