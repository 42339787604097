import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class PostPriceService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(postId, post_prices_attributes) {
    const params = {
      post: { post_prices_attributes },
    }

    const { data } = await this.httpClient.post(
      `/api/posts/${postId}/collection_post_prices`,
      params
    )

    const { flush } = data
    const postPrices = this.serializer.parseResourceData(data.post_prices, data.post_prices.data)

    return { postPrices, flush }
  }
}

export default PostPriceService
