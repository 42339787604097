import ClassNames from 'classnames'
import { clamp } from 'lodash'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'
import { isTouchDevice } from '../../../utils'
import Slider from '../Slider'
import SVG from 'react-inlinesvg'

interface IProps {
  name: string
  field: string
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

interface IPrice {
  min: string
  max: string
}

const initialParams = new URLSearchParams(window.location.search)
const MAX_PRICE = 100000

const PriceFilter: React.FC<IProps> = props => {
  const getInitialValue = () => ({
    min: initialParams.has('min_price') ? initialParams.get('min_price') : '',
    max: initialParams.has('max_price') ? initialParams.get('max_price') : '',
  })
  const getLabelText = updatedPrice => {
    if (!updatedPrice.min && !updatedPrice.max) {
      return props.name
    } else if (updatedPrice.min && !updatedPrice.max) {
      return `¥${updatedPrice.min} 〜`
    } else if (!updatedPrice.min && updatedPrice.max) {
      return `〜 ¥${updatedPrice.max}`
    } else if (updatedPrice.min && updatedPrice.max) {
      return `¥${updatedPrice.min} 〜 ¥${updatedPrice.max}`
    }
  }
  const [isOpen, setIsOpen] = React.useState(false)
  const [price, setPrice] = React.useState<any>(getInitialValue())
  const [labelName, setLabelName] = React.useState(getLabelText(getInitialValue()))
  const [clear, setClear] = React.useState(false)
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [isOpen]
  )

  const setFormatLabelText = React.useCallback(() => {
    setLabelName(getLabelText(price))
  }, [price])

  const hasValue = React.useCallback(() => {
    return price.min || price.max
  }, [price])

  const onClear = () => {
    setPrice({ min: '', max: '' })
    setFormatLabelText()
    setClear(true)
  }

  const onApply = React.useCallback(() => {
    const filterValue: any = {
      min_price: price.min,
      max_price: price.max,
    }
    props.handleOnSubmit(filterValue)
    // TODO API CALL
    setPanelIsOpen(false)
    setFormatLabelText()
  }, [price, setPanelIsOpen, props.handleOnSubmit])

  const onChangeHandler = React.useCallback(
    event => {
      const changedPrice = {
        ...price,
        [event.target.name]: event.target.value,
      }
      setPrice(changedPrice)
    },
    [price]
  )

  const onBlurHandler = React.useCallback(
    event => {
      const clampedValue =
        event.target.name === 'min'
          ? clamp(
              Number(event.target.value),
              0,
              Number(price.max) || Number(event.target.value) + 1
            )
          : clamp(Number(event.target.value), Number(price.min), MAX_PRICE)

      const changedPrice = {
        ...price,
        [event.target.name]: clampedValue,
      }
      setPrice(changedPrice)
    },
    [price]
  )

  const [dragging, setDragging] = React.useState(false)

  React.useEffect(() => {
    if (clear) {
      setFormatLabelText()
      setClear(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear])

  const onSliderValueChange = changedValue => {
    setPrice({
      min: Math.floor(MAX_PRICE * changedValue.min),
      max: Math.floor(MAX_PRICE * changedValue.max),
    })
  }

  const getSliderValueByPrice = currentPrice => {
    return {
      min: currentPrice.min ? clamp(Number(currentPrice.min) / MAX_PRICE, 0, 1) : 0,
      max:
        currentPrice.max >= currentPrice.min
          ? clamp(Number(currentPrice.max) / MAX_PRICE, 0, 1)
          : 1,
    }
  }
  return (
    <ClickOutside
      onClickOutside={() => {
        if (!dragging) {
          setPanelIsOpen(false)
        }
      }}
    >
      <PriceFilterWrapper className="PriceFilter">
        <FilterLabel
          className={ClassNames('Filter_Label', { filled: isOpen || hasValue() })}
          onClick={() => setPanelIsOpen(!isOpen)}
        >
          {labelName}
          <SVG
            src="/images/icons/keyboard_arrow_down.svg"
            className="icon expand"
            aria-hidden="true"
            role="presentation"
          />
        </FilterLabel>
        {isOpen && (
          <FilterWindow className="FilterWindow">
            <div className="Filter_Head">
              <div onClick={() => setPanelIsOpen(false)} className="close">
                <SVG
                  src="/images/icons/close.svg"
                  className="icon"
                  aria-hidden="true"
                  role="presentation"
                />
              </div>
            </div>
            {!isTouchDevice() && (
              <Slider
                setDragging={setDragging}
                value={getSliderValueByPrice(price)}
                onChangeHandler={onSliderValueChange}
              />
            )}

            <form action="">
              <div className="Price_Inputs">
                <div className="Price_Input">
                  <span className="Price_InputLabel">{I18n.t('generic.min_price')}</span>
                  <span className="Price_InputCurrency">¥</span>
                  <input
                    name="min"
                    value={Number(price.min) <= 0 ? '0' : price.min}
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                  />
                </div>
                <span>-</span>
                <div className="Price_Input">
                  <span className="Price_InputLabel">{I18n.t('generic.max_price')}</span>
                  <span className="Price_InputCurrency">¥</span>
                  <input
                    name="max"
                    value={Number(price.min) <= Number(price.max) ? price.max : price.min}
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                  />
                </div>
              </div>
            </form>
            <FilterFooter>
              <div className={ClassNames('_clear', { disabled: !hasValue() })} onClick={onClear}>
                {I18n.t('generic.clear')}
              </div>
              <div className="_apply" onClick={onApply}>
                {I18n.t('generic.apply')}
              </div>
            </FilterFooter>
          </FilterWindow>
        )}
      </PriceFilterWrapper>
    </ClickOutside>
  )
}

const FilterLabel = styled.div`
  border: solid 1px ${constants.COLORS.divider};
  display: flex;
  align-items: center;
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 18px;
  cursor: pointer;

  &.filled {
    background-color: ${constants.COLORS.primary};
    border: solid 1px ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  &:hover:not(.filled) {
    border: solid 1px ${constants.COLORS.primary};
    color: ${constants.COLORS.primary};
  }
`

const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 0;

  ._clear,
  ._apply {
    font-weight: bold;
    cursor: pointer;
  }

  ._clear {
    color: ${constants.COLORS.subText};

    &.disabled {
      visibility: hidden;
    }
  }

  ._apply {
    color: ${constants.COLORS.primary};
  }
`

const PriceFilterWrapper = styled.div`
  position: relative;

  .Price_Inputs {
    position: relative;
    display: flex;
    align-items: center;

    input {
      display: block;
      width: 160px;
      padding: 24px 12px 8px 28px;
      border: solid 1px ${constants.COLORS.divider};
      border-radius: 4px;
      font-size: 15px;
      transition: border 0.2s ease;
      outline: none;
      background: none;
      @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
        width: 100%;
      }
    }
  }

  .Price_Input {
    position: relative;
    margin: 0 8px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      width: 50%;
    }
  }

  .Price_InputLabel {
    position: absolute;
    left: 10px;
    top: 8px;
    font-size: 12px;
    line-height: 1;
  }

  .Price_InputCurrency {
    position: absolute;
    top: 25px;
    left: 12px;
    line-height: 1;
    font-size: 16px;
    height: 16px;
    line-height: 1;
  }
`

const FilterWindow = styled.div`
  position: absolute;
  left: 0;
  bottom: -8px;
  transform: translateY(100%);
  background-color: ${constants.COLORS.white};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  padding: 16px;
  border-radius: 4px;
  z-index: 1100;
  min-width: 280px;
  .Filter_Head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transition: background-color 0.2s;
      cursor: pointer;
      &:hover {
        background-color: ${constants.COLORS.cardBG};
      }
    }
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    position: fixed;
    top: 52px;
    left: 0;
    right: 0;
    height: calc(100vh - 52px);
    transform: none;
    width: auto;
    max-width: 100vw;
  }
`

export default PriceFilter
