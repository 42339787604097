import ClassNames from 'classnames'
import ReactSelectWrap from 'components/pages/reservations/parts/ReactSelectWrap'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'
import SVG from 'react-inlinesvg'

interface IType {
  id: number | string
  name: string
  name_i18n?: string
}

interface IProps {
  field: string
  name: string
  types: IType[]
  className?: string
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

const initialParams = new URLSearchParams(window.location.search)

const TypeFilter: React.FC<IProps> = props => {
  const getInitialValue = () =>
    initialParams.has(`${props.field}[]`) ? initialParams.getAll(`${props.field}[]`) : []
  const [isOpen, setIsOpen] = React.useState(false)
  const [types, setTypes] = React.useState(getInitialValue())
  const [labelName, setLabelName] = React.useState(props.name)
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [isOpen]
  )

  const onChangeHandler = event => {
    const value = event.target.value

    if (event.target.checked) {
      if (!types.includes(value)) {
        setTypes([...types, value])
      }
    } else {
      if (types.includes(value)) {
        setTypes(types.filter(type => type !== value))
      }
    }
  }

  React.useEffect(() => {
    if (types.length) {
      const updatedLabelName = props.types
        .map(type => (types.includes(String(type.id)) ? type.name : null))
        .filter(type => type !== null)
        .join(', ')
      setLabelName(updatedLabelName)
    } else {
      setLabelName(props.name)
    }
  }, [types])

  const hasValue = () => types.length
  const onClear = () => {
    setTypes([])
  }
  const onApply = React.useCallback(() => {
    props.handleOnSubmit({ [props.field]: types })
    setPanelIsOpen(false)
  }, [types, setPanelIsOpen, props.handleOnSubmit])

  return (
    <ClickOutside onClickOutside={() => setPanelIsOpen(false)}>
      <TypeFilterWrapper className="TypeFilter">
        <FilterLabel
          className={ClassNames('Filter_Label', { filled: isOpen || hasValue() })}
          onClick={() => setPanelIsOpen(!isOpen)}
        >
          {labelName}
          <SVG
            src="/images/icons/keyboard_arrow_down.svg"
            className="icon expand"
            aria-hidden="true"
            role="presentation"
          />
        </FilterLabel>
        {isOpen && (
          <FilterWindow className="FilterWindow">
            <div className="Filter_Head">
              <div onClick={() => setPanelIsOpen(false)} className="close">
                <SVG
                  src="/images/icons/close.svg"
                  className="icon"
                  aria-hidden="true"
                  role="presentation"
                />
              </div>
            </div>
            <form action="" className="Filter_Main">
              <CheckBox>
                <div className="CheckBox_Main">
                  {props.types.map(type => (
                    <label key={type.id}>
                      <input
                        type="checkbox"
                        name="type_ids"
                        value={String(type.id)}
                        onChange={onChangeHandler}
                        checked={types.includes(String(type.id))}
                      />
                      <span className="CheckBox_Icon">
                        <SVG
                          src="/images/icons/check_box.svg"
                          className="icon filled"
                          aria-hidden="true"
                          role="presentation"
                        />
                        <SVG
                          src="/images/icons/check_box_outline_blank.svg"
                          className="icon outline"
                          aria-hidden="true"
                          role="presentation"
                        />
                      </span>
                      <span>{type.name_i18n || type.name}</span>
                    </label>
                  ))}
                </div>
              </CheckBox>
            </form>
            <FilterFooter>
              <div className={ClassNames('_clear', { disabled: !hasValue() })} onClick={onClear}>
                {I18n.t('generic.clear')}
              </div>
              <div className="_apply" onClick={onApply}>
                {I18n.t('generic.apply')}
              </div>
            </FilterFooter>
          </FilterWindow>
        )}
      </TypeFilterWrapper>
    </ClickOutside>
  )
}

const FilterWindow = styled.div`
  position: absolute;
  left: 0;
  bottom: -8px;
  transform: translateY(100%);
  background-color: ${constants.COLORS.white};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  padding: 12px 16px;
  border-radius: 4px;
  width: 240px;
  z-index: 1100;
  .Filter_Head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transition: background-color 0.2s;
      cursor: pointer;
      &:hover {
        background-color: ${constants.COLORS.cardBG};
      }
    }
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    position: fixed;
    top: 52px;
    left: 0;
    right: 0;
    height: calc(100vh - 52px);
    transform: none;
    width: auto;
    overflow: auto;
    width: auto;
    max-width: 100vw;
  }
`

const FilterLabel = styled.div`
  border: solid 1px ${constants.COLORS.divider};
  display: flex;
  align-items: center;
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 18px;
  cursor: pointer;

  &.filled {
    background-color: ${constants.COLORS.primary};
    border: solid 1px ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  &:hover:not(.filled) {
    border: solid 1px ${constants.COLORS.primary};
    color: ${constants.COLORS.primary};
  }
`

const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 0;

  ._clear,
  ._apply {
    font-weight: bold;
    cursor: pointer;
  }

  ._clear {
    color: ${constants.COLORS.subText};

    &.disabled {
      visibility: hidden;
    }
  }

  ._apply {
    color: ${constants.COLORS.primary};
  }
`

const TypeFilterWrapper = styled.div`
  position: relative;
`

const CheckBox = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .CheckBox_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .CheckBox_Main {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    flex: 1;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
    }

    > label {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        opacity: 0.75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      .CheckBox_Icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 24px;
        height: 24px;

        .icon {
          position: absolute;
          top: 0;
          left: 0;

          &.filled {
            display: none;
            color: ${constants.COLORS.primary};
          }

          &.outline {
            display: block;
            color: ${constants.COLORS.divider};
          }
        }
      }

      input:checked + .CheckBox_Icon {
        .icon {
          &.filled {
            display: block;
          }

          &.outline {
            display: none;
          }
        }
      }

      span {
        font-size: 12px;
        padding-left: 28px;
      }
    }

    /* > label + label {
      margin-top: 8px;
    } */
  }
`

export default TypeFilter
