import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { COLORS, BREAKPOINT_TABLET_LARGE } from '../../../static/constants'
import ClassNames from 'classnames'

interface IProps {
  type?: 'headline' | 'subtitle' | 'body' | 'button'
  color?:
    | 'black'
    | 'white'
    | 'caution'
    | 'subtext'
    | 'success'
    | 'info'
    | 'link'
    | 'disabled'
    | 'primary'
    | 'secondary'
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  children?: React.ReactNode
}

const Typography: React.VFC<IProps> = ({
  type = 'body',
  color = 'black',
  size = 'md',
  children,
}) => {
  return <S.Typo className={ClassNames(type, size, color)}>{children}</S.Typo>
}

export const S: { [key: string]: AnyStyledComponent } = {}

S.Typo = styled.div`
  &.black{color: ${COLORS.black}}
  &.white{color: ${COLORS.white}}
  &.caution{color: ${COLORS.caution}}
  &.subtext{color: ${COLORS.subText}}
  &.success{color: ${COLORS.success}}
  &.info{color: ${COLORS.info}}
  &.link{color: ${COLORS.textLink}}
  &.disabled{color: ${COLORS.disabled}}
  &.primary{color: ${COLORS.primary}}
  &.secondary{color: ${COLORS.secondary}}
  &.headline,
  &.subtitle,
  &.button {
    font-weight: 700;
  }

  &.headline {
    &.xl {
      font-size: 38px;
    }
    &.lg {
      font-size: 28px;
    }
    &.md {
      font-size: 22px;
      @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
        font-size: 18px;
      }
    }
    &.sm {
      font-size: 18px;
      @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
        font-size: 16px;
      }
    }
    &.xs {
      font-size: 18px;
    }
  }

  &.subtitle,
  &.body {
    &.xl {
      font-size: 16px;
    }
    &.lg {
      font-size: 14px;
    }
    &.md {
      font-size: 12px;
    }
    &.sm {
      font-size: 10px;
    }
    &.xs {
      font-size: 8px;
    }

  &.button {
    &.xl {
      font-size: 14px;
    }
    &.lg {
      font-size: 14px;
    }
    &.md {
      font-size: 14px;
    }
    &.sm {
      font-size: 12px;
    }
    &.xs {
      font-size: 12px;
    }
  }
`

export default Typography
