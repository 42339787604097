import ClassNames from 'classnames'
import { DesktopSearch, MobileSearch } from 'components/atoms/HeaderSearch'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled, { createGlobalStyle } from 'styled-components'
import I18n from '../../core/i18n'
import { userService } from '../../core/services'
import * as constants from '../../static/constants'
import { IsDesktop, IsMobileOrTablet } from '../../utils/responsive'
import Button from './Button'
import LocaleSelect from './LocaleSelect'

interface IProps {
  user: any
  isMobile?: boolean
}
const getRoleLabel = user => {
  if (user.admin) {
    return I18n.t('generic.administrator')
  }
  if (user.agent) {
    return I18n.t('generic.agent')
  }
  if (user.guide) {
    return I18n.t('generic.guide')
  }
  if (user.guest) {
    return I18n.t('generic.guest')
  }
  return ''
}

const PostShowAvatar: React.VFC<IProps> = ({ user, isMobile }) => {
  const PostShowAvatarWrap = isMobile ? MobileAvatar : DestopAvatar

  return (
    <PostShowAvatarWrap user={user}>
      <div className="PostShow_Host">
        <div className="PostShow_Avatar">
          <a
            className="PostShow_AvatarImage"
            href={`/user/${user.username}-${user.id}`}
            target="_blank"
          >
            <img src={user.avatar_url} alt="" loading="lazy" />
          </a>
          <div className="PostShow_AvatarInfo">
            <div className="PostShow_AvatarName">
              <p>{user.username}</p>
              {/*<span>
                {I18n.t('avatar.avatar_created_at', {
                  created_at: user.joined_date,
                })}
              </span> */}
            </div>
            <div className="roleInfo">
              <span className="roleLabel">{getRoleLabel(user)}</span>
              <span className="catchphrase">{user.catchphrase}</span>
            </div>
          </div>
        </div>
      </div>
    </PostShowAvatarWrap>
  )
}

const MobileAvatar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px 0;

  .Avatar_Name {
    display: block;
    margin-left: 12px;

    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .Avatar_Icon {
    width: 40px;
    height: 40px;
    background-color: ${constants.COLORS.white};
    border-radius: 50%;
    overflow: hidden;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

const DestopAvatar = styled.div`

  .PostShow_Avatar {
    display: flex;
    align-items: center;

    .PostShow_AvatarImage {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 24px;
      overflow: hidden;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        margin-right: 12px;
      }

      > img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    .PostShow_AvatarInfo {
      flex: 1;

      .PostShow_AvatarName {

        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: self-start;

        @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
          display: inline;
        }

        p {
          font-size: 18px;
          line-height: 27px;
          font-weight: normal;

          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
            font-size: 14px;
          }
        }

        > span {
          background-color: ${constants.COLORS.background}
          padding: 3px 20px;
          border-radius: 30px;
          font-size: 10px;
          line-height: 15px;
          text-align: center;
          margin-left: 1em;
          display: block;
          @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
            display: inline;
            margin-left: 0;
          }
        }
      }
    }
  }

  .roleInfo {
    height: 100%;
    .catchphrase {
      margin-left: 1em;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
    }
  }
  .roleLabel {
    padding: 2px 8px;
    font-size: 12px;
    line-height: 15px
    font-weight: 700;
    border: ${({ user }) => (user.guest ? `1px solid ${constants.COLORS.secondary}` : 'none')};
    border-radius: 3px;
    background-color: ${props =>
      props.user.guest ? constants.COLORS.white : constants.COLORS.secondary};
    color: ${props => (props.user.guest ? constants.COLORS.secondary : constants.COLORS.white)};
  }

`
export default PostShowAvatar
