import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}

declare var window: IWindow

interface IProps {
  setIsOpen?: (isOpen: boolean) => void
}

const Burger: React.VFC<IProps> = ({ setIsOpen }) => {
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false)
  React.useEffect(() => {
    if (setIsOpen) {
      setIsOpen(isBurgerOpen)
    }
  }, [isBurgerOpen])
  const onClick = () => setIsBurgerOpen(!isBurgerOpen)
  return (
    <BurgerIcon className={ClassNames('burger', { isOpen: isBurgerOpen })} onClick={onClick}>
      <span />
      <span />
      <span />
    </BurgerIcon>
  )
}

const BurgerIcon = styled.div`
  position: relative;
  width: ${constants.MOBILE_HEADER_HEIGHT}px;
  height: ${constants.MOBILE_HEADER_HEIGHT}px;
  transition: background-color 0.2s;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  > span {
    display: block;
    height: 1.5px;
    width: 16px;
    left: calc(50% - 8px);
    position: absolute;
    background-color: currentColor;
    transform-origin: center;
    transition-duration: 86ms;
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-out;

    &:nth-child(1) {
      top: calc(50% - 6px);
    }

    &:nth-child(2) {
      top: calc(50% - 1px);
    }

    &:nth-child(3) {
      top: calc(50% + 4px);
    }
  }

  &.isOpen {
    > span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
`

export default Burger
