import I18n from 'core/i18n'
import * as React from 'react'
import styled from 'styled-components'
import { utilService } from '../../core/services'
import {
  BREAKPOINT_TABLET_SMALL,
  BREAKPOINT_TABLET_LARGE,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
} from '../../static/constants'
import { Score } from '../atoms'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

SwiperCore.use([Autoplay])

interface IPostListProps {
  slider_post: any
}

const TopSlider: React.FC<IPostListProps> = props => {
  const { data: post } = utilService.getDataFromJson(props.slider_post)
  const getPostType = (postType: string) => {
    if (postType === 'private') {
      return I18n.locale === 'ja' ? 'プライベート' : 'Private'
    }
    return I18n.locale === 'ja' ? 'グループ' : 'Group'
  }
  React.useEffect(() => {
    const sliderLS = document.getElementById('slider-ls')
    sliderLS.style.display = 'none'
  })

  return (
    <Wrapper>
      <div className="Image">
        <Swiper
          slidesPerView={'auto'}
          slidesPerGroup={1}
          spaceBetween={20}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
          breakpoints={{
            720: {
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          {post.post_images.map(image => (
            <SwiperSlide key={image.id}>
              <a
                href={`/posts/${post.slug}`}
                aria-label="to post detail page"
                target="_blank"
                rel="noopener"
              >
                <img
                  src={image?.large_url || image?.image_url}
                  alt=""
                  aria-hidden="true"
                  loading="eager"
                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="Item">
        <div key={post.id} className="PostItem">
          <a
            href={`/posts/${post.slug}`}
            aria-labelledby={post.name}
            target="_blank"
            rel="noopener"
          >
            <div className="PostItem_Name">{post.name}</div>
            <div className="PostItem_Inner">
              <div className={`PostItem_PostType ${post.post_type}`}>
                {getPostType(post.post_type)}
              </div>
              <div className="PostItem_Price">
                ¥{post.price} · {post.duration} hours
              </div>
              <div className="PostItem_Score">
                <Score score={post.average_review_score} />
              </div>
            </div>
          </a>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .Image {
    position: relative;
    width: 100%;
    overflow: hidden;
    .swiper-slide {
      width: 80%;
    }
    a {
      display: block !important;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 25 / 14;
      border-radius: 10px;
      vertical-align: bottom;
    }
  }

  .Item {
    padding: 24px 0;
    @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
      padding-left: 16px;
      padding-right: 16px;
    }
    .PostItem {
      > a {
        color: ${COLORS.black};
      }
    }

    .PostItem_Name {
      font-size: 20px;
      font-weight: bold;
      overflow: hidden;
      @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
        font-size: 16px;
      }
    }

    .PostItem_Inner {
      display: flex;
      align-items: center;
      @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
        flex-wrap: wrap;
      }
    }

    .PostItem_PostType {
      line-height: 16px;
      padding: 1px 10px;
      margin: 10px 16px 0 0;
      font-size: 12px;
      font-weight: bold;
      border: 1px solid ${COLORS.primary};
      border-radius: 2px;
      &.private {
        color: ${COLORS.white};
        background-color: ${COLORS.primary};
      }
      &.group {
        color: ${COLORS.primary};
        background-color: ${COLORS.white};
        border-color: ${COLORS.primary};
      }
    }

    .PostItem_Price {
      color: ${COLORS.subText};
      font-size: 16px;
      margin: 10px 16px 0 0;
    }

    .PostItem_Score {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .count {
        font-size: 8px;
        margin-left: 4px;
        color: ${COLORS.subText};
      }
    }

    .icon {
      font-size: 12px;
      cursor: pointer;
      position: absolute;
      top: 18px;
      &:hover {
        color: ${COLORS.primary};
      }
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        top: 30px;
      }
    }
    .next_arrow {
      right: 30px;
    }
    .prev_arrow {
      right: 60px;
      @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
        right: 72px;
      }
    }
  }
`

export default TopSlider
