import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class RemindMailTemplateService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async createOrUpdate(post_id, params) {
    const { data } = await this.httpClient.put(`/api/posts/${post_id}/remind_mail_template`, params)
    const { remind_mail_template, flush } = data
    const remindMailTemplate = this.serializer.parseResourceData(
      remind_mail_template,
      remind_mail_template.data
    )

    return { remindMailTemplate, flush }
  }
}

export default RemindMailTemplateService
