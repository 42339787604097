import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class TourService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async getTourGuides(tour_id) {
    const { data } = await this.httpClient.get(`/api/tours/${tour_id}/tour_guides`)
    const tourGuides = this.serializer.parseResourceData(data.tour_guides, data.tour_guides.data)

    return { tourGuides }
  }

  public async assignGuide(tour_group_id, guide_id) {
    const { data } = await this.httpClient.put(`/api/tour_groups/${tour_group_id}/assign_guide`, {
      guide_id,
    })
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group }
  }

  public async unassignGuide(tour_group_id) {
    const { data } = await this.httpClient.put(`/api/tour_groups/${tour_group_id}/unassign_guide`)
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group }
  }

  public async assignReservation(
    tour_group_id,
    param: {
      reservable_type: string
      reservable_id: number
    }
  ) {
    const { data } = await this.httpClient.put(
      `/api/tour_groups/${tour_group_id}/assign_reservation`,
      {
        ...param,
      }
    )

    const { flush } = data
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group, flush }
  }

  public async updateTourGroup(tour_group_id, param: any) {
    const { data } = await this.httpClient.patch(`/api/tour_groups/${tour_group_id}`, {
      ...param,
    })
    const { flush } = data
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group, flush }
  }

  public async unassignReservation(
    tour_group_id,
    param: {
      reservable_type: string
      reservable_id: number
    }
  ) {
    const { data } = await this.httpClient.put(
      `/api/tour_groups/${tour_group_id}/unassign_reservation`,
      {
        ...param,
      }
    )
    const { flush } = data
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group, flush }
  }

  public async searchTour(params) {
    const { data } = await this.httpClient.get(`/api/tours/search`, { params })
    const tour = this.serializer.parseResourceData(data.tour, data.tour.data)

    return { tour }
  }

  public async createTour(params) {
    const { data } = await this.httpClient.post(`/api/tours`, { ...params })
    const { flush } = data
    const tour = this.serializer.parseResourceData(data.tour, data.tour.data)

    return { tour, flush }
  }
  public async deleteTour(id) {
    const { data } = await this.httpClient.delete(`/api/tours/${id}`)
    const { flush } = data

    return { flush }
  }

  public async createTourGroup(params) {
    const { data } = await this.httpClient.post(`/api/tour_groups`, { ...params })
    const { flush } = data
    const tour_group = this.serializer.parseResourceData(data.tour_group, data.tour_group.data)

    return { tour_group, flush }
  }
  public async deleteTourGroup(id) {
    const { data } = await this.httpClient.delete(`/api/tour_groups/${id}`)
    const { flush } = data

    return { flush }
  }
  public async getReservationReportByTourGroupId(tourGroupId) {
    const { data } = await this.httpClient.get(`/api/tour_groups/${tourGroupId}/reservation_report`)
    const reservation_report = data.reservation_report
      ? this.serializer.parseResourceData(data.reservation_report, data.reservation_report.data)
      : null

    return reservation_report
  }
  public async getPaymentItemByTourGroupId(tourGroupId) {
    const { data } = await this.httpClient.get(`/api/tour_groups/${tourGroupId}/payment_item`)
    const payment_item = data.payment_item
      ? this.serializer.parseResourceData(data.payment_item, data.payment_item.data)
      : null

    return payment_item
  }
}

export default TourService
