import { AxiosInstance } from 'axios'
import moment from 'moment'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class UtilService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(resource) {
    const data = this.serializer.parseResourceData(resource, resource.data)

    return { data }
  }

  public formatPriceForForm: (price: string | number) => string = price => {
    return typeof price === 'number' ? price.toString() : price.replace(',', '')
  }

  public separate = (stringNum: string) => {
    const len = stringNum.length

    if (len > 3) {
      return this.separate(stringNum.substring(0, len - 3)) + ',' + stringNum.substring(len - 3)
    } else {
      return stringNum
    }
  }

  public formatPrice = (price: number) => {
    return `¥${this.separate(String(price))}`
  }
  public formatPriceOnlyYen = (price: number) => {
    return `¥${String(price)}`
  }
  public formatDateFromLocale = (date: string, locale: string, format: string) => {
    if (locale === 'ja') {
      return moment(date, 'YYYYMMDD').format(format)
    }
    return moment(date).format(format)
  }
}

export default UtilService
