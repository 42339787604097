import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class ConversationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getConversationFromJson(data) {
    const conversation = this.serializer.parseResourceData(data, data.data)

    return { conversation }
  }

  public getMessageFromJson(data) {
    const message = this.serializer.parseResourceData(data, data.data)

    return { message }
  }

  public getConversationsFromJson(data) {
    // console.log(data)
    const conversations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { conversations, pagination }
  }
}

export default ConversationService
