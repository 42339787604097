import * as moment from 'moment'
import React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { createGlobalStyle } from 'styled-components'
import * as constants from '../../static/constants'
import { Button } from '../atoms'
import { Form } from '../molecules'
import { IsMobile, IsTabletOrDesktop } from 'utils/responsive'
import SVG from 'react-inlinesvg'

const FIELDS = {
  area: 'area',
  date: 'date',
}

interface IArea {
  id: number
  name: string
  name_ja: string
}

const SearchPanel: React.FC<{ areas: IArea[]; title: string }> = props => {
  const [focused, setFocused] = React.useState(null)
  const [selectedArea, setSelectedArea] = React.useState('')
  const [selectedDate, setSelectedDate] = React.useState(null)
  const handleCalendarOutSideRange = React.useCallback(calendarMoment => {
    if (calendarMoment.isBefore(moment())) {
      return true
    }
  }, [])
  const onDateChange = React.useCallback((date: moment.Moment | null) => {
    setSelectedDate(date)
  }, [])
  const handleSubmit = React.useCallback(
    (initialValues, values) => {
      let queryParams = `?${values.area && 'area_ids[]=' + values.area}`

      if (selectedDate !== null) {
        queryParams += `&date=${selectedDate.format('YYYY-MM-DD')}`
      }

      location.href = `/posts/search${queryParams}`
    },
    [selectedDate]
  )
  const isMobile = matchMedia(`(max-width: ${constants.BREAKPOINT_TABLET_SMALL}px)`).matches

  const heading = (
    <div>
      <h1 className="heading">{props.title}</h1>
      <p className="text">{I18n.t('search_pan.sub_title')}</p>
    </div>
  )
  const onChangeArea = e => {
    setSelectedArea(e.target.value)
  }

  React.useEffect(() => {
    const searchLS = document.getElementById('search-ls')
    searchLS.style.display = 'none'
  })

  const formComponent = (
    <Form fields={FIELDS} handleSubmit={handleSubmit}>
      <div className="SearchInputs">
        <div className="SearchPanel_Inputs">
          <div className="SearchPanel_FormItem">
            <SVG
              src="/images/icons/place.svg"
              className="icon"
              aria-hidden="true"
              role="presentation"
            />
            <select
              className={`SearchPanel_AreaInput${selectedArea !== '' ? '' : ' blank'}`}
              name="area"
              placeholder="Area"
              onChange={onChangeArea}
              aria-label="Add area to filter"
            >
              <option key="blank" value="">
                {I18n.t('search_pan.search_by_area')}
              </option>
              {props.areas.map(area => (
                <option key={area.id} value={area.id}>
                  {I18n.locale === 'ja' && area.name_ja ? area.name_ja : area.name}
                </option>
              ))}
            </select>
          </div>
          <div className="SearchPanel_FormItem">
            <SVG
              src="/images/icons/date_range.svg"
              className="icon"
              aria-hidden="true"
              role="presentation"
            />
            <DateRangePickerCustomStyle />
            <SingleDatePicker
              id="date"
              date={selectedDate}
              placeholder={I18n.locale === 'ja' ? '日付' : 'Add Date'}
              onDateChange={onDateChange}
              focused={focused}
              onFocusChange={changed => setFocused(changed.focused)}
              enableOutsideDays={false}
              isOutsideRange={handleCalendarOutSideRange}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
          <Button primary={true} small={isMobile} aria-label="Submit">
            {/* {I18n.t('search_pan.search')} */}
            <SVG
              src="/images/icons/search.svg"
              className="icon"
              aria-hidden="true"
              role="presentation"
            />
          </Button>
        </div>
      </div>
    </Form>
  )
  return (
    <SearchPanelWrapper className="SearchPanel">
      {heading}
      {formComponent}
    </SearchPanelWrapper>
  )
}

const SearchPanelWrapper = styled.div`
  background-color: ${constants.COLORS.white};
  border-radius: 4px;
  width: 100%;
  max-width: 640px;
  padding: 60px 0;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    padding: 32px 0;
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    width: 100vw;
    max-width: none;
    background-color: transparent;

    text-align: center;
    margin: 0 auto;
    padding: 0 0 32px;
  }

  .heading {
    font-size: 40px;
    line-height: 1.36;
    margin-top: 0;
    white-space: pre-wrap;
    color: ${constants.COLORS.primary};
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XXL}px) {
      font-size: 36px;
    }
    @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
      font-size: 34px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      font-size: 28px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin: 24px 0 2px;
      font-size: 22px;
    }
  }

  .text {
    font-size: 20px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      font-size: 16px;
    }
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .SearchInputs {
    display: flex;
    max-width: 320px;
    margin-top: 36px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: block;
      width: 100%;
      margin-top: 16px;
    }

    .Button {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin-left: 12px;
      padding: 0;
      border-radius: 18px;
    }
  }

  .SearchPanel_Inputs {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 6px;
    border: 1px solid ${constants.COLORS.divider};
    border-radius: 24px;
    background-color: ${constants.COLORS.white};
  }
  .SearchPanel_FormItem {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(50% - 8px);
    height: 35px;
    padding: 0 4px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      min-width: auto;
    }

    > .icon {
      margin: auto;
      color: ${constants.COLORS.primary};
      z-index: 10;
    }
  }

  .icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    font-size: 24px;
    overflow: hidden;
  }

  .SearchPanel_AreaInput {
    appearance: none;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    color: ${constants.COLORS.black};
    outline: none;
    border: none;

    width: 100%;
    min-width: 86px;
    height: 26px;
    margin-left: 6px;
    padding: 2px 0;
    border-radius: 0;
    &:focus {
      border-bottom: solid 2px ${constants.COLORS.primary};
    }
    &.blank {
      color: ${constants.COLORS.subText};
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      min-width: 70px;
      margin-left: 4px;
    }
  }

  .SearchPanel_DateRangeLabels {
    display: flex;

    > label {
      width: 50%;
    }
  }
`

const DateRangePickerCustomStyle = createGlobalStyle`

  .SingleDatePicker {
    display: block;
    width: 100%;
  }

  .DateRangePickerInput.DateRangePickerInput_1 {
    width: 100%;
    border: none;
  }

  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
    display: none;
  }

  .DateInput {
    width: 100%;
  }
  .DateInput_input {
    padding: 0;
    color: ${constants.COLORS.subText};
    font-size: 16px;
    font-weight: 400;
  }

  .SingleDatePickerInput__withBorder {
    width: 100%;
    min-width: 86px;
    border: none;
    margin-left: 8px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      min-width: 64px;
      margin-left: 6px;
    }
  }

  .CalendarDay__selected_span {
    background: ${constants.COLORS.secondary};
    border: 1px double ${constants.COLORS.secondary};
    color: ${constants.COLORS.white};
  }

  .CalendarDay__selected {
    background: ${constants.COLORS.primary};
    border: 1px double ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  .CalendarDay__selected:hover {
    background: ${constants.COLORS.primary};
    color: ${constants.COLORS.white};
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${constants.COLORS.secondary};
    border: 1px double ${constants.COLORS.secondary};
    color: ${constants.COLORS.white};
  }
`

export default SearchPanel
