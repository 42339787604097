import httpClient from '../httpClient'
import JsonApiSerializer from '../JsonApiSerializer'
import AdminService from './AdminService'
import AgentService from './AgentService'
import ConversationService from './ConversationService'
import GuideService from './GuideService'
import MeetingPointService from './MeetingPointService'
import PostCalendarService from './PostCalendarService'
import PostImageService from './PostImageService'
import PostPriceService from './PostPriceService'
import PostService from './PostService'
import PostStartTimeService from './PostStartTimeService'
import PostVideoService from './PostVideoService'
import DirectReservationService from './DirectReservationService'
import ReservationService from './ReservationService'
import ReservationReportService from './ReservationReportService'
import RemindMailTemplateService from './RemindMailTemplateService'
import SettingsService from './SettingsService'
import ThanksMailService from './ThanksMailService'
import UserCalendarService from './UserCalendarService'
import UserService from './UserService'
import UtilService from './UtilService'
import TourService from './ToursService'

const jsonApiSerializer = new JsonApiSerializer()

export const conversationService = new ConversationService(httpClient, jsonApiSerializer)
export const postService = new PostService(httpClient, jsonApiSerializer)
export const postPriceService = new PostPriceService(httpClient, jsonApiSerializer)
export const postVideoService = new PostVideoService(httpClient, jsonApiSerializer)
export const postCalendarService = new PostCalendarService(httpClient, jsonApiSerializer)
export const postImageService = new PostImageService(httpClient, jsonApiSerializer)
export const postStartTimeService = new PostStartTimeService(httpClient, jsonApiSerializer)
export const adminService = new AdminService(httpClient, jsonApiSerializer)
export const agentService = new AgentService(httpClient, jsonApiSerializer)
export const guideService = new GuideService(httpClient, jsonApiSerializer)
export const userService = new UserService(httpClient, jsonApiSerializer)
export const userCalendarService = new UserCalendarService(httpClient, jsonApiSerializer)
export const directReservationService = new DirectReservationService(httpClient, jsonApiSerializer)
export const reservationService = new ReservationService(httpClient, jsonApiSerializer)
export const reservationReportService = new ReservationReportService(httpClient, jsonApiSerializer)
export const remindMailTemplateService = new RemindMailTemplateService(
  httpClient,
  jsonApiSerializer
)
export const settingsService = new SettingsService(httpClient, jsonApiSerializer)
export const meetingPointService = new MeetingPointService(httpClient, jsonApiSerializer)
export const utilService = new UtilService(httpClient, jsonApiSerializer)
export const thanksMailService = new ThanksMailService(httpClient, jsonApiSerializer)
export const tourService = new TourService(httpClient, jsonApiSerializer)
