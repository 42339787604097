import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class MeetingPointService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(params) {
    const { data } = await this.httpClient.post(`/api/meeting_points`, params)
    const { meeting_point, flush } = data
    const meetingPoint = this.serializer.parseResourceData(meeting_point, meeting_point.data)

    return { meetingPoint, flush }
  }

  public async update(id, params) {
    const { data } = await this.httpClient.patch(`/api/meeting_points/${id}`, params)
    const { meeting_point, flush } = data
    const meetingPoint = this.serializer.parseResourceData(meeting_point, meeting_point.data)

    return { meetingPoint, flush }
  }

  public async delete(id) {
    const { data } = await this.httpClient.delete(`/api/meeting_points/${id}`)
    const { flush } = data

    return { flush }
  }
}

export default MeetingPointService
