import I18n from 'core/i18n'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

import { Swiper, SwiperSlide } from 'swiper/react'

const TopPickup: React.FC<{}> = props => {
  React.useEffect(() => {
    const pickupLS = document.getElementById('pickup-ls')
    pickupLS.style.display = 'none'
  })

  return (
    <Wrapper>
      <div className="Slides">
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={20}
          rewind={false}
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
          breakpoints={{
            720: {
              slidesPerView: 3,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
        >
          <SwiperSlide>
            <a href="/lps/tsukiji-tours" aria-label="to landing page" className="tsukiji">
              <span>{I18n.locale === 'ja' ? '築地' : 'Tsukiji Tours'}</span>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/lps/sumo-tours" aria-label="to landing page" className="sumo">
              <span>{I18n.locale === 'ja' ? '相撲' : 'Sumo Tours'}</span>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="/lps/private-tours-japan" aria-label="to landing page" className="private">
              <span>{I18n.locale === 'ja' ? 'プライベート' : 'Private Tours'}</span>
            </a>
          </SwiperSlide>
        </Swiper>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .Slides {
    width: 100%;
    overflow: hidden;
    .swiper-slide {
      height: auto;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        width: 35%;
      }
      > a {
        height: 100%;
      }
    }

    a {
      position: relative;
      display: block !important;
      aspect-ratio: 5 / 2;
      background-size: cover;
      background-position: top center;
      border-radius: 8px;
      transition: opacity 0.2s;
      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        aspect-ratio: 2 / 1;
      }
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        aspect-ratio: 5 / 4;
        background-position: center;
      }
      span {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 44px 24px 16px;
        color: ${constants.COLORS.white};
        font-size: 40px;
        font-weight: 700;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
        border-radius: 0 0 8px 8px;
        @media (max-width: ${constants.BREAKPOINT_DESKTOP_XXL}px) {
          font-size: 32px;
          padding-bottom: 12px;
        }
        @media (max-width: ${constants.BREAKPOINT_DESKTOP_XL}px) {
          font-size: 28px;
        }
        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          font-size: 24px;
          padding: 32px 16px 8px;
        }
        @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
          font-size: 16px;
          padding: 32px 8px 8px;
          text-align: center;
        }
      }
      &:hover {
        opacity: 0.85;
      }
      &.tsukiji {
        background-image: url(/images/top/pickup-tsukiji.png);
      }
      &.sumo {
        background-image: url(/images/top/pickup-sumo.png);
      }
      &.private {
        background-image: url(/images/top/pickup-private.png);
      }
    }
  }
`

export default TopPickup
