import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import InputLabel from './InputLabel'

interface IProps {
  name: string
  value?: string
  defaultValue?: string
  label?: null | string
  placeholder?: null | string
  error?: null | string
  required?: boolean
  optional?: boolean
  fullWidth?: boolean
  disabled?: boolean
  readonly?: boolean
  postEdit?: boolean
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputTextArea: React.FC<IProps> = props => {
  const initialValueFromProps = props.defaultValue ?? props.value
  const [localValue, setLocalValue] = React.useState<string>(initialValueFromProps ?? '')

  React.useEffect(() => {
    if (props.value !== null && props.value !== undefined) {
      setLocalValue(props.value)
    }
  }, [props.value])
  return (
    <InputTextAreaWrapper className="InputTextArea">
      {props.label && <InputLabel label={props.label} required={props.required} />}

      <div className="InputTextArea_Main">
        {props.postEdit && <span className="InputText_PostUrl">{I18n.t('post.post_url')}</span>}
        <textarea
          name={props.name}
          placeholder={props.placeholder}
          required={props.required ? true : false}
          disabled={props.disabled}
          readOnly={props.readonly || false}
          // defaultValue={props.defaultValue}
          value={localValue}
          onChange={event => {
            setLocalValue(event.target.value)
            if (typeof props.onChangeHandler === 'function') {
              props.onChangeHandler(event)
            }
          }}
          onBlur={event => {
            setLocalValue(event.target.value)
            if (typeof props.onBlurHandler === 'function') {
              props.onBlurHandler(event)
            }
          }}
        />
        {props.error && <span className="InputTextArea_Error">{props.error}</span>}
      </div>
    </InputTextAreaWrapper>
  )
}

const InputTextAreaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  textarea {
    display: block;
    width: 100%;
    height: 160px;
    padding: 8px 12px;
    border: solid 1px ${constants.COLORS.divider};
    border-radius: 4px;
    font-size: 14px;
    transition: border 0.2s ease;
    outline: none;
    background: none;
    line-height: 1.5;

    &:focus {
      border: solid 1px ${constants.COLORS.primary};
    }

    &:read-only {
      background-color: ${constants.COLORS.divider};
    }
  }

  .InputTextArea_Main {
    width: 100%;
    flex: 1;
  }

  .InputText_PostUrl {
    font-size: 13px;
    font-weight: bold;
  }

  .InputTextArea_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.COLORS.cautionText};
    font-size: 14px;
  }
`

export default InputTextArea
