import I18n from 'core/i18n'
import * as React from 'react'
import { COLORS } from 'static/constants'
import { IUser } from 'static/interfaces'
import styled from 'styled-components'

interface IProps {
  user: IUser
}
const getRoleLabel = user => {
  if (user.admin) {
    return I18n.t('generic.administrator')
  }
  if (user.agent) {
    return I18n.t('generic.agent')
  }
  if (user.guide) {
    return I18n.t('generic.guide')
  }
  if (user.guest) {
    return I18n.t('generic.guest')
  }
  return ''
}
const RoleLabel: React.VFC<IProps> = ({ user }) => {
  return <Label user={user}>{getRoleLabel(user)}</Label>
}
const getBG = ({ guest, guide, agent }) => {
  if (guest) {
    return COLORS.white
  }
  if (guide) {
    return COLORS.secondary
  }
  if (agent) {
    return COLORS.primary
  }
}

const Label = styled.div`
  width: fit-content;
  text-align: center;
  padding: 2px 8px;
  flex-grow: 0;
  font-size: 12px;
  font-weight: 700;
  border: ${({ user }) => (user.guest ? `1px solid ${COLORS.primary}` : 'none')};
  border-radius: 3px;
  background-color: ${({ user }) => getBG(user)};
  color: ${({ user }) => (user.guest ? COLORS.primary : COLORS.white)};
`

export default RoleLabel
