import * as React from 'react'
import styled from 'styled-components'
import { PostCard } from 'components/molecules'
import { utilService } from '../../core/services'
import * as constants from '../../static/constants'
import { checkLikeable } from 'utils'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

import SVG from 'react-inlinesvg'

SwiperCore.use([Navigation])

interface ITopPostsProps {
  user: any
  posts: any
  pickup?: boolean
}

const MOBILE_LIMIT = 4

const TopPosts: React.VFC<ITopPostsProps> = props => {
  const { data: _posts } = utilService.getDataFromJson(props.posts)
  const isMobile = matchMedia(`(max-width: ${constants.BREAKPOINT_TABLET_SMALL}px)`).matches
  const posts = isMobile ? _posts.filter((_, i) => i < MOBILE_LIMIT) : _posts
  const [navigate, setNavigate] = React.useState(false)

  const swiperRef = React.useRef<SwiperCore>()

  React.useEffect(() => {
    const bestSellersLS = document.getElementById('best-sellers-ls')
    bestSellersLS.style.display = 'none'

    const elements = Array.from(
      document.getElementsByClassName('c-slider') as HTMLCollectionOf<HTMLElement>
    )
    elements.map(element => {
      element.style.display = 'none'
    })
  })

  const updateNavigation = React.useCallback(() => {
    if (swiperRef.current) {
      // @ts-ignore
      setNavigate(swiperRef.current.slides.length > swiperRef.current.visibleSlides.length)
    }
  }, [swiperRef])

  return (
    <TopPostsWrapper>
      <Swiper
        slidesPerView={'auto'}
        slidesPerGroup={1}
        spaceBetween={20}
        rewind={false}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        breakpoints={{
          720: {
            slidesPerView: 2,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            rewind: true,
          },
          768: {
            slidesPerView: 3,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            rewind: true,
          },
          1200: {
            slidesPerView: 4,
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
            rewind: true,
          },
        }}
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        watchSlidesProgress={true}
        onResize={updateNavigation}
      >
        {posts.map(post => (
          <SwiperSlide key={post.id}>
            <PostCard
              post={post}
              pickup={props.pickup}
              likeable={checkLikeable(post, props.user)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={navigate ? 'NavContainer' : 'NavContainer navDisabled'}>
        <button type="button" onClick={() => swiperRef.current?.slidePrev()} aria-label="Prev Post">
          <SVG
            src="/images/icons/keyboard_arrow_left.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </button>
        <button type="button" onClick={() => swiperRef.current?.slideNext()} aria-label="Next Post">
          <SVG
            src="/images/icons/keyboard_arrow_right.svg"
            className="icon"
            aria-hidden="true"
            role="presentation"
          />
        </button>
      </div>
    </TopPostsWrapper>
  )
}

const TopPostsWrapper = styled.div`
  position: relative;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    overflow: hidden;
  }
  .swiper-slide {
    height: auto;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 240px;
    }
    > a {
      height: 100%;
    }
  }

  .NavContainer {
    position: absolute;
    top: -52px;
    right: 0;
    display: flex;
    align-items: center;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: none;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      padding: 0;
      background: ${constants.COLORS.white};
      border: 1px solid ${constants.COLORS.divider};
      border-radius: 50%;
      appearance: none;
      cursor: pointer;
      transition: border-color 0.2s;
      .icon {
        color: ${constants.COLORS.primary};
      }
      & + button {
        margin-left: 10px;
      }
      &:hover {
        border-color: ${constants.COLORS.primary};
      }
    }
    &.navDisabled {
      button {
        border: 1px solid #dcdee4;
        background: ${constants.COLORS.background};
        cursor: default;
        .icon {
          color: ${constants.COLORS.disabled};
        }
      }
    }
  }
`

export default TopPosts
