import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class ReservationReportService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  // 報告
  public async createReservationReport(reservationReport, params) {
    const { data } = await this.httpClient.post(
      `/api/tour_groups/${reservationReport.reportable.id}/reservation_report`,
      {
        ...params,
      }
    )
    const { flush } = data
    const reservation_report = this.serializer.parseResourceData(
      data.reservation_report,
      data.reservation_report.data
    )

    return { reservation_report, flush }
  }

  public async updateReservationReport(reservationReport, params) {
    const { data } = await this.httpClient.put(
      `/api/tour_groups/${reservationReport.reportable.id}/reservation_report`,
      {
        ...params,
      }
    )
    const { flush } = data
    const reservation_report = this.serializer.parseResourceData(
      data.reservation_report,
      data.reservation_report.data
    )

    return { reservation_report, flush }
  }
}

export default ReservationReportService
