import ClassNames from 'classnames'
import { DesktopSearch, MobileSearch } from 'components/atoms/HeaderSearch'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled, { createGlobalStyle } from 'styled-components'
import I18n from '../../core/i18n'
import { userService } from '../../core/services'
import * as constants from '../../static/constants'
import { IsDesktop, IsMobileOrTablet } from '../../utils/responsive'
import Button from './Button'
import LocaleSelect from './LocaleSelect'

interface IProps {
  user: any
  isMobile?: boolean
}
const getRoleLabel = user => {
  if (user.admin) {
    return I18n.t('generic.administrator')
  }
  if (user.agent) {
    return I18n.t('generic.agent')
  }
  if (user.guide) {
    return I18n.t('generic.guide')
  }
  if (user.guest) {
    return I18n.t('generic.guest')
  }
  return ''
}

const Avatar: React.VFC<IProps> = ({ user, isMobile }) => {
  const AvatarWrap = isMobile ? MobileAvatar : DesktopAvatar

  return (
    <AvatarWrap user={user}>
      <div className="Avatar_Icon">
        <img src={user.avatar_url} />
      </div>
      <div className="Avatar_Info">
        <div className="Avatar_Name">{user.username}</div>
        <span className="roleLabel">{getRoleLabel(user)}</span>
        {/*
        <div className="roleInfo">
          <span className="hostName">{user && ''}</span>
        </div>
        */}
      </div>
    </AvatarWrap>
  )
}

const MobileAvatar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px 0;

  .Avatar_Name {
    display: block;
    margin-left: 12px;

    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .Avatar_Icon {
    width: 40px;
    height: 40px;
    background-color: ${constants.COLORS.cardBG};
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

const DesktopAvatar = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  height: 44px;

  .Avatar_Icon {
    width: 44px;
    height: 44px;
    margin-right: 12px;
    background: ${constants.COLORS.cardBG};
    border-radius: 50%;
    // margin-left: 10px;
    overflow: hidden;
    flex-shrink: 0;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .Avatar_Name {
    max-width: 160px;
    height: 24px;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Avatar_Info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .roleLabel {
    display: inline-block;
    line-height: 15px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    border: ${({ user }) => (user.guest ? `1px solid ${constants.COLORS.primary}` : 'none')};
    border-radius: 3px;
    background-color: ${props =>
      props.user.guest ? constants.COLORS.white : constants.COLORS.primary};
    color: ${props => (props.user.guest ? constants.COLORS.primary : constants.COLORS.white)};
  }
`
export default Avatar
