import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import InputLabel from './InputLabel'

interface IProps {
  name: string
  label?: string
  checked?: boolean
  defaultChecked?: boolean
  onChangeHandler?(event: any): void
}

const Toggle: React.FC<IProps> = props => {
  return (
    <ToggleWrapper className="Toggle">
      {props.label && <InputLabel label={props.label} />}
      <div className="Toggle_Main">
        {typeof props.defaultChecked === 'boolean' ? (
          <input name={props.name} type="checkbox" defaultChecked={props.defaultChecked} />
        ) : (
          <input
            name={props.name}
            type="checkbox"
            checked={props.checked}
            onChange={props.onChangeHandler}
          />
        )}
        <span className="slider round" />
      </div>
      <div className="Toggle_State">{props.checked ? 'ON' : 'OFF'}</div>
    </ToggleWrapper>
  )
}

const ToggleWrapper = styled.label`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .Toggle_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .Toggle_Main {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 28px;
  }

  .Toggle_State {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 700;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${constants.COLORS.divider};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 2px;
    background-color: ${constants.COLORS.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${constants.COLORS.primary};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${constants.COLORS.primary};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

export default Toggle
