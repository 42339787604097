import { LocaleSelect } from 'components/atoms'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

const Footer: React.FC<{ isTop?: boolean }> = props => {
  const className = props.isTop ? 'top' : ''
  return (
    <S.Footer className={className}>
      <div className="footer-inner">
        <S.List className={className}>
          <li>
            <a href="/terms_of_use">{I18n.t('generic.terms_of_use')}</a>
          </li>
          <li>
            <a href={I18n.t('generic.policy_ur')}>{I18n.t('generic.privacy_policy')}</a>
          </li>
          <li>
            <LocaleSelect />
          </li>
        </S.List>
        <S.Copyright className={className}>Copyright(c) Compass Corp.</S.Copyright>
      </div>
    </S.Footer>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Footer = styled.div`
  padding: 0 80px;
  background: ${constants.COLORS.black};
  color: ${constants.COLORS.white};
  @media (max-width: ${constants.BREAKPOINT_DESKTOP - 1}px) {
    padding: 0 50px;
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 24px 0;
  }
  .footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;
    max-width: 1440px;
    margin: 0 auto;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  &.top {
    margin-top: 64px;
    .footer-inner {
      flex-direction: column;
      min-height: 284px;
      padding: 80px 0;
      align-items: flex-center;
      @media (max-width: ${constants.BREAKPOINT_DESKTOP - 1}px) {
        min-height: 120px;
        margin-top: 0;
        padding: 24px 0;
      }
    }
  }
`

S.List = styled.ul`
  display: flex;
  max-width: 360px;
  width: 100%;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    width: auto;
    margin-bottom: 20px;
    justify-content: center;
  }
  li {
    font-size: 14px;
    > a {
      color: ${constants.COLORS.white};
      :hover {
        color: ${constants.COLORS.primary};
      }
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin: 0 12px !important;
    }
  }

  li + li {
    margin-left: 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin-left: 12px;
    }
  }
  &.top {
    width: auto;
    max-width: none;
    margin-bottom: 80px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP - 1}px) {
      margin-bottom: 24px;
    }
    li {
      margin: 0 30px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        margin: 0 12px;
      }
    }
  }
`

S.Copyright = styled.span`
  display: block;
  font-size: 12px;
  text-align: center;
  &.top {
    width: 100%;
  }
`

export default Footer
