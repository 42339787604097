import { clamp, defer } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IValue {
  min: number | null
  max: number | null
}

interface IProps {
  value: IValue
  setDragging?(dragging: boolean): void
  onChangeHandler?(value: IValue): any
}

const Slider: React.FC<IProps> = props => {
  const sliderRef = React.useRef(null)
  const [dragItem, setDragItem] = React.useState(null)
  const [sliderValue, setSliderValue] = React.useState({ min: null, max: null })

  // Set slider value by passed value
  React.useEffect(() => {
    const sliderWidth = sliderRef.current.offsetWidth
    setSliderValue({ min: sliderWidth * props.value.min, max: sliderWidth * props.value.max })
  }, [props.value])

  const onDragStart = React.useCallback(
    event => {
      if (dragItem) {
        return
      }
      event.persist()
      const sliderWidth = sliderRef.current.offsetWidth
      const boundingClientRect = sliderRef.current.getBoundingClientRect()
      const value = event.nativeEvent.pageX - boundingClientRect.left
      const targetDragItem =
        Math.abs(sliderValue.min - value) < Math.abs(sliderValue.max - value) ? 'start' : 'end'
      const nextSliderValue =
        targetDragItem === 'start'
          ? { min: value, max: sliderValue.max }
          : { min: sliderValue.min, max: value }

      if (typeof props.onChangeHandler === 'function') {
        props.onChangeHandler({
          min: nextSliderValue.min / sliderWidth,
          max: nextSliderValue.max / sliderWidth,
        })
      }

      setDragItem(targetDragItem)
      setSliderValue(nextSliderValue)
      if (typeof props.setDragging === 'function') {
        props.setDragging(true)
      }
    },
    [sliderValue, dragItem]
  )

  const onDragEnd = event => {
    defer(() => {
      setDragItem(null)
      if (typeof props.setDragging === 'function') {
        props.setDragging(false)
      }
    })
  }

  React.useEffect(() => {
    const sliderWidth = sliderRef.current.offsetWidth
    const boundingClientRect = sliderRef.current.getBoundingClientRect()

    const onMouseMoveHandler = event => {
      const value = event.pageX - boundingClientRect.left

      setSliderValue(currentValue => {
        const clampedValue =
          dragItem === 'start'
            ? clamp(value, 0, currentValue.max)
            : clamp(value, currentValue.min, sliderWidth)

        const changedValue =
          dragItem === 'start'
            ? { min: clampedValue, max: currentValue.max }
            : { min: currentValue.min, max: clampedValue }

        if (typeof props.onChangeHandler === 'function') {
          props.onChangeHandler({
            min: changedValue.min / sliderWidth,
            max: changedValue.max / sliderWidth,
          })
        }

        return changedValue
      })
    }

    if (dragItem) {
      window.addEventListener('mousemove', onMouseMoveHandler)
      window.addEventListener('mouseup', onDragEnd)
      document.body.style.setProperty('-webkit-user-select', 'none')
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMoveHandler)
      window.removeEventListener('mouseup', onDragEnd)
      document.body.style.removeProperty('-webkit-user-select')
    }
  }, [dragItem])

  return (
    <SliderWrapper className="Slider" onMouseDown={onDragStart} ref={sliderRef}>
      {typeof sliderValue.min === 'number' && (
        <div className="Slider_Min" style={{ left: `${sliderValue.min}px` }} />
      )}
      {typeof sliderValue.min === 'number' && typeof sliderValue.max === 'number' && (
        <>
          <div className="Slider_Rail _base" />
          <div
            className="Slider_Rail _active"
            style={{ left: sliderValue.min, width: `${sliderValue.max - sliderValue.min}px` }}
          />
        </>
      )}
      {typeof sliderValue.max === 'number' && (
        <div className="Slider_Max" style={{ left: `${sliderValue.max}px` }} />
      )}
    </SliderWrapper>
  )
}

const SliderWrapper = styled.div`
  position: relative;
  height: 32px;
  margin: 0 16px 16px;
  cursor: pointer;

  .Slider_Min,
  .Slider_Max {
    -webkit-user-select: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    background-color: ${constants.COLORS.primary};
    border-radius: 50%;
    box-shadow: 0 0 3px 0 rgba(21, 27, 38, 0.15);
    z-index: 1000;
  }

  .Slider_Min {
    left: 0;
    transform: translateX(-50%);
  }

  .Slider_Max {
    left: 320px;
    transform: translateX(-50%);
  }

  .Slider_Rail {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 2px;

    &._active {
      background-color: ${constants.COLORS.primary};
    }

    &._base {
      background-color: ${constants.COLORS.divider};
      left: 0;
      width: 100%;
    }
  }
`

export default Slider
