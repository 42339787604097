import React from 'react'
import { COLORS } from 'static/constants'
import styled from 'styled-components'
import IconButton from './IconButton'

interface ICounter {
  defaultValue?: number
  minValue?: number
  isLimit?: boolean
  handleChange?: (count: number) => void
  disabled?: boolean
}

const Counter: React.VFC<ICounter> = ({
  defaultValue,
  minValue = 0,
  isLimit = false,
  handleChange,
  disabled = false,
}) => {
  const [count, setCount] = React.useState<number>(defaultValue || minValue)

  const minus = () => {
    if (disabled) {
      return
    }
    if (count > minValue) {
      setCount(old => old - 1)
    }
  }

  const add = () => {
    if (disabled) {
      return
    }
    if (!isLimit) {
      setCount(old => old + 1)
    }
  }

  React.useEffect(() => {
    if (handleChange) {
      handleChange(count)
    }
  }, [count])

  return (
    <CounterWrapper>
      <IconButton
        size="sm"
        iconName="remove"
        disabled={disabled || count <= minValue}
        handleClick={minus}
      />
      <span className="number">{count}</span>
      <IconButton size="sm" handleClick={add} iconName="add" disabled={disabled || isLimit} />
    </CounterWrapper>
  )
}

const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  .number {
    display: block;
    font-size: 14px;
    font-weight: 700;
    width: 20px;
    text-align: center;
    margin: 0 16px;
  }
`

export default Counter
