import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

const Required: React.VFC = () => {
  return <RequiredLabel>{I18n.t('generic.required')}</RequiredLabel>
}

const RequiredLabel = styled.div`
  display: inline-block;
  color: ${constants.COLORS.cautionText};
  margin-left: 8px;
  font-size: 10px;
  font-weight: 700;
  flex-shrink: 0;
`

export default Required
