import * as React from 'react'
import { BREAKPOINT_TABLET_SMALL } from 'static/constants'
import styled, { AnyStyledComponent } from 'styled-components'

interface IProps {
  children: React.ReactNode
  justifyContent?: 'space-between' | 'center' | 'flex-start' | 'flex-end'
  buttonSize?: number
  inTable?: boolean
  inline?: boolean
  width?: string
}

const Buttons: React.FC<IProps> = ({
  children,
  buttonSize,
  justifyContent = 'center',
  inTable = false,
  inline = false,
  width,
}) => {
  return (
    <S.Buttons
      buttonSize={buttonSize}
      justifyContent={justifyContent}
      inline={inline}
      inTable={inTable}
      width={width}
    >
      {children}
    </S.Buttons>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}

S.Buttons = styled.div`
  width: ${({ width }) => (width ? width : 'fit-content')};
  display: flex;
  ${({ inTable }) => inTable && `flex-direction: column;`}
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ inline, inTable }) => (inline || inTable ? '0' : '40px 10px 10px')};

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin: ${({ inline }) => (inline ? '0' : '20px 0 10px')};
    flex-wrap: wrap;
    flex-direction: row;
  }

  button {
    min-width: ${({ buttonSize }) => (buttonSize ? `${buttonSize}px` : 'auto')};
    margin: 4px;
  }

`

export default Buttons
