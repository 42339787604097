import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

interface IParams {
  month?: string //  // YYYY-MM
  startDate?: string // YYYY-MM-DD
  endDate?: string // YYYY-MM-DD
  count?: number
}
class UserCalendarService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }
  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async get(params: IParams) {
    const { data } = await this.httpClient.get(`/api/user_calendars`, {
      params: { ...params },
    })
    const {
      user_calendars_json,
      post_calendars_json,
      tour_reservations_json,
      tour_guides_json,
    } = data
    const userCalendars = this.serializer.parseResourceData(
      user_calendars_json,
      user_calendars_json.data
    )
    const postCalendars = this.serializer.parseResourceData(
      post_calendars_json,
      post_calendars_json.data
    )
    const tourGuides = this.serializer.parseResourceData(tour_guides_json, tour_guides_json.data)
    const pagable = data.pagable
    return { userCalendars, postCalendars, tourGuides, pagable }
  }

  public async getDetails(params) {
    const { data } = await this.httpClient.get(`/api/user_calendars/details`, {
      params: { ...params },
    })
    const {
      // user_calendars_json,
      // post_calendars_json,
      // tour_reservations_json,
      tour_guides_json,
    } = data
    // const userCalendars = this.serializer.parseResourceData(
    //   user_calendars_json,
    //   user_calendars_json.data
    // )
    // const postCalendars = this.serializer.parseResourceData(
    //   post_calendars_json,
    //   post_calendars_json.data
    // )
    const tourGuides = this.serializer.parseResourceData(tour_guides_json, tour_guides_json.data)
    // const pagable = data.pagable
    return { tourGuides }
  }

  public async collect(user_calendars_attributes) {
    const params = {
      user: { user_calendars_attributes },
    }

    const { data } = await this.httpClient.post(`/api/collection_user_calendars`, params)

    const { flush } = data
    const userCalendars = this.serializer.parseResourceData(
      data.user_calendars,
      data.user_calendars.data
    )

    return { userCalendars, flush }
  }

  public async getMemberData(params, userId) {
    const { data } = await this.httpClient.get(`/api/user_calendars/member_calendar`, {
      params: { ...params, user_id: userId },
    })
    const userCalendars = this.serializer.parseResourceData(
      data.user_calendars,
      data.user_calendars.data
    )
    const postCalendars = this.serializer.parseResourceData(
      data.post_calendars,
      data.post_calendars.data
    )
    const guideCalendars = data.guide_calendars
      ? this.serializer.parseResourceData(data.guide_calendars, data.guide_calendars.data)
      : null
    const tourGuides = data.tour_guides_json
      ? this.serializer.parseResourceData(data.tour_guides_json, data.tour_guides_json.data)
      : null
    const posts = data.posts ? this.serializer.parseResourceData(data.posts, data.posts.data) : null
    const pagable = data.pagable
    return { userCalendars, postCalendars, guideCalendars, tourGuides, posts, pagable }
  }
  // public async cancelPreAssign(param) {
  //   const { data } = await this.httpClient.put('/api/pre_assign/un_assign', param)

  //   const { flush } = data
  //   return { flush }
  // }
}

export default UserCalendarService
