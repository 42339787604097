import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  tag: { name: string; id: string } | null | undefined
}

const Tag: React.VFC<IProps> = ({ tag }) => {
  if (!tag) {
    return null
  }
  return <TagWrapper>{tag.name}</TagWrapper>
}

const TagWrapper = styled.div`
  display: block;
  border-radius: 20px;
  padding: 2px 16px;
  font-size: 10px;
  font-weight: 700;
  background: ${constants.COLORS.infoBG};
  color: ${constants.COLORS.subText};
`

export default Tag
