import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class AdminService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getIdentificationsFromJson(data) {
    const identifications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { identifications, pagination }
  }

  public async approveIdentification(id) {
    const { data } = await this.httpClient.put(`/admin/api/identifications/${id}/approve`)
    const identification = this.serializer.parseResourceData(
      data.identification,
      data.identification.data
    )

    return { identification, flush: data.flush }
  }

  public async declineIdentification(id) {
    const { data } = await this.httpClient.put(`/admin/api/identifications/${id}/decline`)
    const identification = this.serializer.parseResourceData(
      data.identification,
      data.identification.data
    )

    return { identification, flush: data.flush }
  }

  public getGuideApplicationsFromJson(data) {
    const guide_applications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { guide_applications, pagination }
  }

  public getAgentApplicationsFromJson(data) {
    const agent_applications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { agent_applications, pagination }
  }

  public async approveGuideApplication(id) {
    const { data } = await this.httpClient.put(`/admin/api/guide_applications/${id}/approve`)
    const guide_application = this.serializer.parseResourceData(
      data.guide_application,
      data.guide_application.data
    )

    return { guide_application, flush: data.flush }
  }

  public async declineGuideApplication(id, formValues) {
    const { data } = await this.httpClient.put(
      `/admin/api/guide_applications/${id}/decline`,
      formValues
    )
    const guide_application = this.serializer.parseResourceData(
      data.guide_application,
      data.guide_application.data
    )

    return { guide_application, flush: data.flush }
  }

  public async approveAgentApplication(id) {
    const { data } = await this.httpClient.put(`/admin/api/agent_applications/${id}/approve`)
    const agent_application = this.serializer.parseResourceData(
      data.agent_application,
      data.agent_application.data
    )

    return { agent_application, flush: data.flush }
  }

  public async declineAgentApplication(id, formValues) {
    const { data } = await this.httpClient.put(
      `/admin/api/agent_applications/${id}/decline`,
      formValues
    )
    const agent_application = this.serializer.parseResourceData(
      data.agent_application,
      data.agent_application.data
    )

    return { agent_application, flush: data.flush }
  }

  public async createCategory(category) {
    const { data } = await this.httpClient.post(`/admin/api/categories`, { category })
    const createdCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { createdCategory, flush: data.flush }
  }

  public async updateCategory(category) {
    const { data } = await this.httpClient.patch(`/admin/api/categories/${category.id}`, {
      category,
    })
    const updatedCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { updatedCategory, flush: data.flush }
  }

  public async updateCategories(categories_ids) {
    const { data } = await this.httpClient.post(`/admin/api/update_all_categories`, {
      categories_ids,
    })
    const updatedCategories = this.serializer.parseResourceData(
      data.categories,
      data.categories.data
    )

    return { updatedCategories, flush: data.flush }
  }

  public async deleteCategory(id) {
    const { data } = await this.httpClient.delete(`/admin/api/categories/${id}`)

    return { flush: data.flush }
  }

  public async createArea(formData) {
    const { data } = await this.httpClient.post(`/admin/api/areas`, formData)
    const createdArea = this.serializer.parseResourceData(data.area, data.area.data)

    return { createdArea, flush: data.flush }
  }

  public async updateArea(id, formData) {
    const { data } = await this.httpClient.patch(`/admin/api/areas/${id}`, formData)
    const updatedArea = this.serializer.parseResourceData(data.area, data.area.data)

    return { updatedArea, flush: data.flush }
  }

  public async updateAreas(areas_ids) {
    const { data } = await this.httpClient.post(`/admin/api/update_all_areas`, {
      areas_ids,
    })
    const updatedAreas = this.serializer.parseResourceData(data.areas, data.areas.data)

    return { updatedAreas, flush: data.flush }
  }

  public async deleteArea(id) {
    const { data } = await this.httpClient.delete(`/admin/api/areas/${id}`)

    return { flush: data.flush }
  }

  public async createPickup(pickup) {
    const { data } = await this.httpClient.post(`/admin/api/pickups`, { pickup })
    const createdPickup = this.serializer.parseResourceData(data.pickup, data.pickup.data)

    return { createdPickup, flush: data.flush }
  }

  public async updatePickup(pickup) {
    const { data } = await this.httpClient.patch(`/admin/api/pickups/${pickup.id}`, {
      pickup,
    })
    const updatedPickup = this.serializer.parseResourceData(data.pickup, data.pickup.data)

    return { updatedPickup, flush: data.flush }
  }

  public async updatePickups(pickup_ids) {
    const { data } = await this.httpClient.post(`/admin/api/update_all_pickups`, {
      pickup_ids,
    })
    const updatedPickups = this.serializer.parseResourceData(data.pickups, data.pickups.data)

    return { updatedPickups, flush: data.flush }
  }

  public async deletePickup(id) {
    const { data } = await this.httpClient.delete(`/admin/api/pickups/${id}`)

    return { flush: data.flush }
  }

  public async createTag(tag) {
    const { data } = await this.httpClient.post('/admin/api/tags/', { tag })
    const createdTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { createdTag, flush: data.flush }
  }

  public async updateTag(tag) {
    const { data } = await this.httpClient.patch(`/admin/api/tags/${tag.id}`, { tag })
    const updatedTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { updatedTag, flush: data.flush }
  }

  public async updateTags(tags_ids) {
    const { data } = await this.httpClient.post(`/admin/api/update_all_tags`, {
      tags_ids,
    })
    const updatedTags = this.serializer.parseResourceData(data.tags, data.tags.data)

    return { updatedTags, flush: data.flush }
  }

  public async deleteTag(id) {
    return this.httpClient.delete(`/admin/api/tags/${id}`)
  }

  public async createTourSkills(tourSkills) {
    const { data } = await this.httpClient.post('/admin/api/tour_skills/', {
      tour_skill: tourSkills,
    })
    const createdTourSkills = this.serializer.parseResourceData(
      data.tour_skill,
      data.tour_skill.data
    )

    return { createdTourSkills, flush: data.flush }
  }

  public async updateTourSkills(tourSkills) {
    const { data } = await this.httpClient.patch(`/admin/api/tour_skills/${tourSkills.id}`, {
      tour_skill: tourSkills,
    })
    const updatedTourSkills = this.serializer.parseResourceData(
      data.tour_skill,
      data.tour_skill.data
    )

    return { updatedTourSkills, flush: data.flush }
  }

  public async deleteTourSkills(id) {
    return this.httpClient.delete(`/admin/api/tour_skills/${id}`)
  }

  public async createSkills(skill) {
    const { data } = await this.httpClient.post('/admin/api/skills/', {
      skill,
    })
    const createdSkills = this.serializer.parseResourceData(data.skill, data.skill.data)

    return { createdSkills, flush: data.flush }
  }

  public async updateSkills(skill) {
    const { data } = await this.httpClient.patch(`/admin/api/skills/${skill.id}`, {
      skill,
    })
    const updatedSkills = this.serializer.parseResourceData(data.skill, data.skill.data)

    return { updatedSkills, flush: data.flush }
  }

  public async deleteSkills(id) {
    return this.httpClient.delete(`/admin/api/skills/${id}`)
  }

  public async createPost(post) {
    const { data } = await this.httpClient.post(`/admin/api/posts`, post)
    const createdPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { createdPost, flush: data.flush }
  }

  public async updatePost(post) {
    const { data } = await this.httpClient.patch(`/admin/api/posts/${post.id}`, { post })
    const updatedPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { updatedPost, flush: data.flush }
  }

  public async deletePost(id) {
    return await this.httpClient.delete(`/admin/api/posts/${id}`)
  }

  public async openPost(id) {
    return await this.httpClient.put(`/admin/api/posts/${id}/open`)
  }

  public async closePost(id) {
    return await this.httpClient.put(`/admin/api/posts/${id}/close`)
  }

  public async searchPost(params) {
    const { data } = await this.httpClient.get('/admin/api/posts/search', { params })
    const posts = this.serializer.parseResourceData(data.posts.posts, data.posts.posts.data)
    const pagination = data.posts.posts.meta

    return { posts, pagination }
  }

  public async getPostById(id) {
    const { data } = await this.httpClient.get(`/admin/api/posts/${id}`)
    const post = this.serializer.parseResourceData(data.post, data.post.data)
    return { post }
  }

  public async createUser(user) {
    const { data } = await this.httpClient.post('/admin/api/users', user)
    const createdUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { createdUser, flush: data.flush }
  }

  public async updateUser(param) {
    const { data } = await this.httpClient.patch(`/admin/api/users/${param.id}`, { user: param })
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flush: data.flush }
  }

  public async deleteUser(id) {
    const {
      data: { flush },
    } = await this.httpClient.delete(`/admin/api/users/${id}`)

    return { flush }
  }

  public async inactivateUser(id) {
    const {
      data: { user, flush },
    } = await this.httpClient.put(`/admin/api/users/${id}/inactivate`)

    return { user, flush }
  }

  public async activateUser(id) {
    const {
      data: { user, flush },
    } = await this.httpClient.put(`/admin/api/users/${id}/activate`)

    return { user, flush }
  }

  public async searchUsers(params) {
    const { data } = await this.httpClient.get('/admin/api/users/search', { params })
    const users = this.serializer.parseResourceData(data.users.users, data.users.users.data)
    const pagination = data.users.users.meta

    return { users, pagination }
  }

  public async getUserRelations(id) {
    const { data } = await this.httpClient.get(`/admin/api/users/${id}/relations`)
    const users = this.serializer.parseResourceData(data.users, data.users.data)

    return { users }
  }

  public async updateReservation(reservation, isReservation) {
    const { id } = reservation
    const param = isReservation
      ? { reservation }
      : {
          direct_reservation: { ...reservation },
        }

    const { data } = await this.httpClient.patch(`/admin/api/reservations/${id}`, param)

    const updatedReservation = this.serializer.parseResourceData(
      data.reservation,
      data.reservation.data
    )

    return { updatedReservation, flush: data.flush }
  }

  public async deleteReview(id) {
    const {
      data: { flush },
    } = await this.httpClient.delete(`/admin/api/reviews/${id}`)

    return { flush }
  }

  public async createPromotion(promotion) {
    const { data } = await this.httpClient.post(`/admin/api/promotions`, { promotion })
    const createdPromotion = this.serializer.parseResourceData(data.promotion, data.promotion.data)

    return { createdPromotion, flush: data.flush }
  }

  public async updatePromotion(promotion) {
    const { data } = await this.httpClient.patch(`/admin/api/promotions/${promotion.id}`, {
      promotion,
    })
    const updatedPromotion = this.serializer.parseResourceData(data.promotion, data.promotion.data)

    return { updatedPromotion, flush: data.flush }
  }

  public async deletePromotion(id) {
    const { data } = await this.httpClient.delete(`/admin/api/promotions/${id}`)

    return { flush: data.flush }
  }

  public async updateGuideComment(id, formValues) {
    const { data } = await this.httpClient.put(
      `/admin/api/guides/${id}/update_guide_comment`,
      formValues
    )
    return { flush: data.flush }
  }

  public async updateAdminComment(id, calendar, formValues) {
    const putParams = { day: calendar.day, comment: formValues.comment }
    const { data } = await this.httpClient.put(
      `/admin/api/guides/${id}/update_admin_comment`,
      putParams
    )
    return { flush: data.flush }
  }

  public async createCoupon(coupon) {
    const { data } = await this.httpClient.post(`/admin/api/coupons`, { coupon })
    const createdCoupon = this.serializer.parseResourceData(data.coupon, data.coupon.data)

    return { createdCoupon, flush: data.flush }
  }

  public async updateCoupon(id, coupon) {
    const { data } = await this.httpClient.put(`/admin/api/coupons/${id}`, {
      coupon,
    })
    const updatedCoupon = this.serializer.parseResourceData(data.coupon, data.coupon.data)

    return { updatedCoupon, flush: data.flush }
  }

  public async deleteCoupon(id) {
    const { data } = await this.httpClient.delete(`/admin/api/coupons/${id}`)

    return { flush: data.flush }
  }

  public async createMailTemplate(mail_template) {
    const { data } = await this.httpClient.post(`/admin/api/mail_templates`, { mail_template })
    const createdMailTemplate = this.serializer.parseResourceData(
      data.mail_template,
      data.mail_template.data
    )

    return { createdMailTemplate, flush: data.flush }
  }

  public async updateMailTemplate(id, mail_template) {
    const { data } = await this.httpClient.put(`/admin/api/mail_templates/${id}`, { mail_template })
    const updatedMailTemplate = this.serializer.parseResourceData(
      data.mail_template,
      data.mail_template.data
    )

    return { updatedMailTemplate, flush: data.flush }
  }

  public async deleteMailTemplate(id) {
    const { data } = await this.httpClient.delete(`/admin/api/mail_templates/${id}`)

    return { flush: data.flush }
  }

  public async updatePayment(payment) {
    const { data } = await this.httpClient.patch(`/admin/api/payments/${payment.id}`, {
      payment,
    })
    const updatedPayment = this.serializer.parseResourceData(data.payment, data.payment.data)

    return { updatedPayment, flush: data.flush }
  }

  public async updatePaymentItem(payment_item) {
    const { data } = await this.httpClient.patch(`/admin/api/payment_items/${payment_item.id}`, {
      payment_item,
    })
    const updatedPaymentItem = this.serializer.parseResourceData(
      data.payment_item,
      data.payment_item.data
    )

    return { updatedPaymentItem, flush: data.flush }
  }

  public async sendPaymentNotification(payment) {
    const { data } = await this.httpClient.post(
      `/admin/api/payments/${payment.id}/send_notification`
    )
    return { updatedPayment: data.payment, flush: data.flush }
  }
}

export default AdminService
