import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

// https://github.com/epicmaxco/epic-spinners/blob/master/src/components/lib/HollowDotsSpinner.vue
const Spinner: React.FC<{}> = props => {
  return (
    <SpinnerWrapper className="Spinner">
      <div className="hollow-dots-spinner">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </SpinnerWrapper>
  )
}

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .hollow-dots-spinner,
  .hollow-dots-spinner * {
    box-sizing: border-box;
  }

  .hollow-dots-spinner {
    height: 15px;
    width: calc(30px * 3);
  }

  .hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    background-color: ${constants.COLORS.primary};
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 900ms ease infinite 0ms;
  }

  .hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(180ms * 1);
  }

  .hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(180ms * 2);
  }

  .hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(180ms * 3);
  }

  @keyframes hollow-dots-spinner-animation {
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export default Spinner
