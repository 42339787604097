import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  title: string
  rightContent?: React.ReactNode
}

const PanelTitle: React.VFC<IProps> = props => {
  return (
    <TitleWithButton>
      <h1 className="Title">{props.title}</h1>
      {props.rightContent && props.rightContent}
    </TitleWithButton>
  )
}

const TitleWithButton = styled.div`
  .Title {
    font-size: 22px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export default PanelTitle
