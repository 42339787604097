import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import InputLabel from './InputLabel'
import SVG from 'react-inlinesvg'

interface IProps {
  label?: string
  options: Array<{ id: string; label: string; value: string }>
  directionRow?: boolean
  defaultValue: any
  name?: string
  required?: boolean
  onChangeHandler?(event: any): void | Promise<void>
}

const InputRadio: React.FC<IProps> = ({
  label,
  options,
  defaultValue,
  name,
  required,
  onChangeHandler,
  directionRow = false,
}) => {
  const [localValue, setLocalValue] = React.useState<string>(defaultValue ?? '')

  React.useEffect(() => {
    if (defaultValue !== null && defaultValue !== undefined) {
      setLocalValue(defaultValue)
    }
  }, [defaultValue])
  return (
    <RadioWrapper>
      {label && <InputLabel label={label} required={required} />}

      <div className={`InputRadio_Labels${directionRow ? ' Row' : ''}`}>
        {options.map(option => (
          <label key={option.id}>
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={option.value === localValue}
              onChange={event => {
                setLocalValue(event.target.value)
                if (typeof onChangeHandler === 'function') {
                  onChangeHandler(event)
                }
              }}
            />
            <span className="Radio_Icon">
              <SVG
                src="/images/icons/radio_button_checked.svg"
                className="icon filled"
                aria-hidden="true"
                role="presentation"
              />
              <SVG
                src="/images/icons/radio_button_unchecked.svg"
                className="icon outline"
                aria-hidden="true"
                role="presentation"
              />
            </span>
            <span>{option.label}</span>
          </label>
        ))}
      </div>
    </RadioWrapper>
  )
}
const RadioWrapper = styled.div`
  display: flex;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .InputRadio_Labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 40px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
      align-items: flex-start;
    }
    label {
      display: inline-flex;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;

      &:hover {
        opacity: 0.75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      .Radio_Icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 24px;
        height: 24px;

        .icon {
          position: absolute;
          top: 0;
          left: 0;

          &.filled {
            display: none;
            color: ${constants.COLORS.primary};
          }

          &.outline {
            display: block;
            color: ${constants.COLORS.divider};
          }
        }
      }

      input:checked + .Radio_Icon {
        .icon {
          &.filled {
            display: block;
          }

          &.outline {
            display: none;
          }
        }
      }

      span {
        font-size: 14px;
        padding-left: 28px;
      }
    }

    /* > label + label {
      margin-left: 40px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        margin-left: 0;
        margin-top: 8px;
      }
    } */

    &.Row {
      display: flex;
      align-items: center;
      > label + label {
        margin-top: 0;
        margin-left: 8px;
      }
    }
  }
`
export default InputRadio
