import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { userService } from '../../core/services'
import * as constants from '../../static/constants'
import LinkButton from './LinkButton'
import SVG from 'react-inlinesvg'

interface IProps {
  unreadNotifications: number
}

interface INotification {
  id: number
  title: string
  created_time_ago: string
  read_at: string
  url: string
}

const HeaderNotifications: React.FC<IProps> = ({ unreadNotifications }) => {
  const [notifications, setNotifications] = React.useState<INotification[]>([])
  const [showNotifications, setShowNotifications] = React.useState(false)

  const getNotifications = async () => {
    if (showNotifications) {
      setShowNotifications(false)
    } else {
      const result = await userService.getNotifications()

      setNotifications(result.notifications)
      setShowNotifications(true)
    }
  }
  const hasUnread = React.useMemo(() => unreadNotifications > 0, [unreadNotifications])
  const iconClassName = React.useMemo(() => {
    if (showNotifications) {
      return ' open'
    }
    if (hasUnread) {
      return ' unread'
    }
    return ''
  }, [showNotifications, hasUnread])

  return (
    <Notifications className={iconClassName}>
      <ClickOutside onClickOutside={() => setShowNotifications(false)}>
        <div className="Header_NotificationsIcon" onClick={getNotifications}>
          <SVG
            src="/images/icons/notifications.svg"
            className={`icon${iconClassName}`}
            aria-hidden="true"
            role="presentation"
          />
          {!showNotifications && hasUnread && <span className="indication" />}
        </div>
        {showNotifications && (
          <ul className="Header_NotificationsList">
            <li>
              <LinkButton href="/notifications">
                <>
                  {I18n.t('generic.show_index')}{' '}
                  <SVG
                    src="/images/icons/arrow_forward.svg"
                    className="icon"
                    aria-hidden="true"
                    role="presentation"
                  />
                </>
              </LinkButton>
            </li>
            {notifications.length > 0 ? (
              notifications.map(notification => (
                <li key={notification.id}>
                  <a href={notification.url}>
                    {notification.title}
                    <br />
                    <span>{notification.created_time_ago}</span>
                  </a>
                </li>
              ))
            ) : (
              <li>{I18n.t('notifications.no_notifications')}</li>
            )}
          </ul>
        )}
      </ClickOutside>
    </Notifications>
  )
}

const Notifications = styled.div`
  position: relative;
  flex-shrink: 0;
  &.open {
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      position: unset;
    }
  }
  .Header_NotificationsList {
    position: absolute;
    bottom: 0;
    right: -8px;
    width: 320px;
    max-height: 50vh;
    overflow: auto;
    padding: 12px 0;
    background-color: ${constants.COLORS.white};
    box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);
    transform: translateY(100%);
    border-radius: 4px;
    font-weight: 700;
    z-index: 1000;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      left: 0;
      right: 0;
      width: 100vw;
    }
    li {
      padding: 4px 16px;
      cursor: pointer;

      &:hover {
        background-color: ${constants.COLORS.successBG};
      }

      > a {
        display: block;
        color: ${constants.COLORS.black};
        font-size: 14px;
        span {
          font-size: 12px;
          color: ${constants.COLORS.subText};
        }
      }
    }
  }

  .Header_NotificationsIcon {
    width: 40px;
    height: 40px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
      background-color: ${constants.COLORS.cardBG};
    }

    > .icon {
      display: block;
      width: 24px;
      height: 24px;
      color: ${constants.COLORS.black};
      &.open {
        color: ${constants.COLORS.secondary};
      }
    }

    > .indication {
      position: absolute;
      right: 6px;
      top: 6px;

      display: block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      padding: 0;
      background-color: ${constants.COLORS.caution};
    }
  }
`

export default HeaderNotifications
