import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import I18n from 'core/i18n'
import InputLabel from 'components/atoms/InputLabel'
import { CheckBox } from 'components/atoms'

interface ICheckBoxes {
  options: Array<{ id: number | string; name: string }>
  checked: Array<{ id: number | string }>
  title: string
  name: string
  required?: boolean
}
const CheckBoxes: React.FC<ICheckBoxes> = ({ options, checked, title, name, required }) => {
  const ids = checked.map(item => item.id)
  return (
    <CheckBoxesWrapper>
      {title && <InputLabel label={title} required={required} />}
      <div className="CheckBox_Main">
        {(!options || options.length === 0) && (
          <>
            <div className="Hidden">
              <input type="checkbox" name={name} readOnly={true} />
            </div>
            <p>{I18n.t('generic.no_data')}</p>
          </>
        )}
        {options.map(data => (
          <CheckBox
            key={data.id}
            label={data.name}
            name={name}
            value={data.id}
            defaultChecked={ids.includes(data.id)}
            showLabelInline={true}
          />
        ))}
      </div>
    </CheckBoxesWrapper>
  )
}

const CheckBoxesWrapper = styled.div`
  display: flex;
  margin: 12px 0;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .CheckBox_Main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .Hidden {
      display: none;
    }
  }
`

export default CheckBoxes
