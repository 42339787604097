import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class UserService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getUserFromJson(data) {
    const user = this.serializer.parseResourceData(data, data.data)

    return { user }
  }

  public getUsersFromJson(data) {
    const users = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { users, pagination }
  }

  public getNotificationsFromJson(data) {
    const notifications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { notifications, pagination }
  }

  public async signIn(formData, usertype) {
    let url = ''
    if (usertype === 'guest') {
      url = '/users/sign_in'
    } else if (usertype === 'guide') {
      url = '/users/sign_in/create_guide'
    } else if (usertype === 'agent') {
      url = '/users/sign_in/create_agent'
    } else if (usertype === 'admin') {
      url = '/users/sign_in/create_admin'
    }
    const { headers } = await this.httpClient.post(url, formData)

    return { redirectUrl: headers.location }
  }

  public async createPasswordResetInstruction(email) {
    const { headers } = await this.httpClient.post('/users/password', email)

    return { redirectUrl: headers.location }
  }

  public async resetPassword(formData) {
    const { headers } = await this.httpClient.put('/users/password', formData)

    return { redirectUrl: headers.location }
  }

  public async createConfirmation(email) {
    const { headers } = await this.httpClient.post('/users/confirmation', email)

    return { redirectUrl: headers.location }
  }

  public async createUser(formData) {
    const {
      data: { flush },
    } = await this.httpClient.post('/users', formData)

    return { flush }
  }

  public async createGuide(formData) {
    const {
      data: { flush },
    } = await this.httpClient.post('/users/create_guide', formData)

    return { flush }
  }

  public async createUserFromPost(formData) {
    const {
      data: { flush },
    } = await this.httpClient.post('/users/create_from_post', formData)

    return { flush }
  }

  public async updateUser(formData) {
    const { data } = await this.httpClient.patch('/api/user', formData)
    const { flush } = data
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flush }
  }

  public async updateUserDetail(params) {
    const { data } = await this.httpClient.patch(`/api/user_details/${params.id}`, {
      user_detail: params,
    })
    const { flush } = data
    const userDetail = this.serializer.parseResourceData(data.user_detail, data.user_detail.data)

    return { userDetail, flush }
  }

  // 未使用
  public async deleteUser(user) {
    return this.httpClient.delete(`/user/${user.id}`)
  }

  public async updateProfile(formData) {
    const { data } = await this.httpClient.patch('/api/user', formData)
    const { flush } = data
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flush }
  }

  public async updatePassword(formData) {
    const {
      data: { flush },
    } = await this.httpClient.put('/password', formData)

    return { flush }
  }

  public async getNotifications() {
    const { data } = await this.httpClient.get('/api/notifications/')
    const notifications = this.serializer.parseResourceData(
      data.notifications,
      data.notifications.data
    )

    return { notifications }
  }

  public async createContact(params) {
    return this.httpClient.post(`/api/contacts`, params)
  }

  // public async preAssign(params) {
  //   const { data } = await this.httpClient.post('/api/pre_assign/assign', params)
  //   const { flush } = data
  //   return { flush }
  // }

  public async createBankAccount(formData) {
    const { data } = await this.httpClient.post('/api/bank_account', formData)
    const { flush } = data
    const updatedBankAccount = this.serializer.parseResourceData(
      data.bank_account,
      data.bank_account.data
    )

    return { updatedBankAccount, flush }
  }

  public async updateBankAccount(formData) {
    const { data } = await this.httpClient.patch('/api/bank_account', formData)
    const { flush } = data
    const updatedBankAccount = this.serializer.parseResourceData(
      data.bank_account,
      data.bank_account.data
    )

    return { updatedBankAccount, flush }
  }
}

export default UserService
