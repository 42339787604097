import { AxiosInstance } from 'axios'
import { IJsonApiSerializer, IJsonResource, IJsonResponse } from '../JsonApiSerializer'

class ThanksMailService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public async postThanksMail(reservationId, formData) {
    const { data, status } = await this.httpClient.post(
      `/api/reservations/${reservationId}/thanks_mails`,
      formData
    )

    return { flush: data.flush, status }
  }
}

export default ThanksMailService
