import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  avatarURL?: string | null | undefined
  size?: number
  className?: string
}

const AvatarImage: React.VFC<IProps> = ({ avatarURL, size, className = '' }) => {
  return (
    <Avatar size={size} className={className}>
      <img src={avatarURL} loading="lazy" />
    </Avatar>
  )
}

const Avatar = styled.div`
  flex-shrink: 0;
  width: ${props => (props.size ? `${props.size}px` : '100%')};
  height: ${props => (props.size ? `${props.size}px` : '100%')};
  max-width: ${props => (props.size ? `${props.size}` : '160')}px;
  max-height: ${props => (props.size ? `${props.size}` : '160')}px;
  background-color: #f4f4f4;
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

export default AvatarImage
