import { get, isEqual } from 'lodash'
import * as React from 'react'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

interface IProps {
  fields: { [key: string]: string }
  handleSubmit?: any
  handleUpdateForm?: any
  customValidation?(field: string, value: any): string | null
}

interface IState {
  isSubmitEnabled: boolean
  errors: { [key: string]: null | string }
}

class Form extends React.Component<IProps, IState> {
  public initialValues: { [key: string]: any }
  public elements: any = null

  constructor(props) {
    super(props)

    this.state = {
      isSubmitEnabled: true,
      errors: {},
    }

    this.initialValues = {}
    this.elements = null
  }

  public componentDidMount() {
    this.initialValues = this.getAllFieldsValues()
    this.setSubmitEnabled()
  }

  public setInitialValues() {
    this.initialValues = this.getAllFieldsValues()
  }

  public setSubmitEnabled = () => {
    if (!this.elements) {
      return
    }

    const requiredFieldsIsFilled = Object.keys(this.props.fields).every((key: string) => {
      const field = this.elements[key]
      return !field.required || (field.required && field.value !== '')
    })
    const hasNoErrors = Object.keys(this.state.errors).every(
      (key: string) => this.state.errors[key] === null
    )

    this.setState({ isSubmitEnabled: requiredFieldsIsFilled && hasNoErrors })

    if (typeof this.props.handleUpdateForm === 'function') {
      this.props.handleUpdateForm(
        this.state.errors,
        requiredFieldsIsFilled && hasNoErrors,
        this.getAllFieldsValues()
      )
    }
  }

  public validate = field => {
    if (field.required && field.value === '') {
      return I18n.t('common_errors.empty')
    }

    if (field.type === 'text' && field.value.length > constants.MAX_TEXT_COUNT) {
      return I18n.t('errors.messages.too_long', { count: constants.MAX_TEXT_COUNT })
    }

    if (field.type === 'textarea' && field.value.length > constants.MAX_TEXTAREA_COUNT) {
      return I18n.t('errors.messages.too_long', { count: constants.MAX_TEXTAREA_COUNT })
    }

    return null
  }

  public validateAllFields = () => {
    const errors = {}
    Object.keys(this.props.fields).forEach(key => {
      errors[key] = this.validate(this.elements[key])
    })

    if (typeof this.props.customValidation === 'function') {
      Object.keys(this.props.fields).forEach(key => {
        const customValidatedError = this.props.customValidation(key, this.elements[key].value)

        if (customValidatedError !== null) {
          errors[key] = customValidatedError
        }
      })
    }

    return errors
  }

  public getAllFieldsValues = () => {
    const values: any = {}
    Object.keys(this.props.fields).forEach(key => {
      if (get(this.elements, `[${key}][0].type`) === 'checkbox') {
        // Multiple CheckBox
        const checkBoxValues = []
        this.elements[key].forEach(checkBox => {
          if (checkBox.checked) {
            checkBoxValues.push(checkBox.value)
          }
        })
        values[key] = checkBoxValues
      } else if (get(this.elements, `[${key}].type`) === 'checkbox') {
        // Boolean CheckBox
        values[key] = this.elements[key].checked
      } else {
        values[key] = this.elements[key].value
      }
    })

    return values
  }

  public handleFormChange = () => {
    this.setSubmitEnabled()
  }

  public handleFormBlur = event => {
    if (event.target.type === 'submit') {
      return
    }

    const field = event.target
    const errors = { ...this.state.errors }

    if (this.validate(field)) {
      errors[field.name] = this.validate(field)
    } else {
      errors[field.name] = null
    }

    if (typeof this.props.customValidation === 'function') {
      const customValidatedError = this.props.customValidation(
        event.target.name,
        event.target.value
      )

      if (customValidatedError !== null) {
        errors[field.name] = customValidatedError
      }
    }

    this.setState({ errors }, () => this.setSubmitEnabled())
  }

  public hasNoErrors = errors => {
    return Object.keys(errors).every((key: string) => errors[key] === null)
  }

  public handleFormSubmit = event => {
    if (event) {
      event.preventDefault()
    }

    const currentErrors = this.validateAllFields()
    const hasNoErrors = this.hasNoErrors(currentErrors)

    if (!isEqual(currentErrors, this.state.errors)) {
      this.setState({ errors: currentErrors })
      if (typeof this.props.handleUpdateForm === 'function') {
        this.props.handleUpdateForm(currentErrors, hasNoErrors)
      }
    }

    if (!hasNoErrors) {
      return
    }

    this.props.handleSubmit(this.initialValues, this.getAllFieldsValues())
  }

  public render() {
    return (
      <form
        className="Form"
        onChange={this.handleFormChange}
        onBlur={this.handleFormBlur}
        onSubmit={this.handleFormSubmit}
        ref={el => (this.elements = el && el.elements)}
      >
        {this.props.children}
      </form>
    )
  }
}

export default Form
