import I18n from 'core/i18n'
import { postService } from 'core/services'
import React from 'react'
import * as constants from '../../static/constants'
import * as interfaces from '../../static/interfaces'

import styled from 'styled-components'

import SVG from 'react-inlinesvg'

declare var window: interfaces.IWindow

interface IProps {
  post: any
  onToggleLike?: (postId, like) => void
}

const FavoriteButton: React.VFC<IProps> = ({ post, onToggleLike }) => {
  const [userLiked, setUserLiked] = React.useState(post.user_liked)
  const toggleLike = async e => {
    e.preventDefault()
    e.stopPropagation()
    const { like } = await postService.toggleLike(post)
    setUserLiked(like.isUserLiked)

    const message = like.isUserLiked ? I18n.t('favorite.add_like') : I18n.t('favorite.delete_like')
    window.flashMessages.addMessage({ text: message, type: 'success' })

    if (onToggleLike) {
      onToggleLike(post.id, like)
    }
  }

  return (
    <ToggleFavorite onClick={toggleLike}>
      {userLiked ? (
        <SVG
          src="/images/icons/favorite.svg"
          className="icon liked"
          aria-hidden="true"
          role="presentation"
        />
      ) : (
        <SVG
          src="/images/icons/favorite_border.svg"
          className="icon"
          aria-hidden="true"
          role="presentation"
        />
      )}
    </ToggleFavorite>
  )
}

const ToggleFavorite = styled.div`
  cursor: pointer;
  color: ${constants.COLORS.white};
  transition: color 0.2s;
  .icon {
    width: 32px;
    height: 32px;
  }
  .liked {
    color: ${constants.COLORS.caution};
    animation: Pulse 0.2s ease-in-out;
  }
  &:hover {
    color: ${constants.COLORS.caution};
  }
  @keyframes Pulse {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

export default FavoriteButton
