import React from 'react'
import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET_SMALL, COLORS } from 'static/constants'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

const Merits: React.VFC = () => {
  React.useEffect(() => {
    const aboutLS = document.getElementById('about-ls')
    aboutLS.style.display = 'none'
  })

  return (
    <MeritsWrap>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        rewind={true}
        navigation={{
          nextEl: '.button-next',
          prevEl: '.button-prev',
        }}
        breakpoints={{
          720: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 64,
          },
        }}
      >
        <SwiperSlide>
          <div className="merits-item">
            <img
              src="/images/top/merit_1.png"
              loading="lazy"
              alt="Merit 1 image"
              aria-hidden="true"
            />
            <h3 className="merits-title">Attractive Contents</h3>
            <p>
              We are NOT a platform. We create original tours and select the best destinations and
              specialized adventures for you to get the most authentic Japanese experience. Enjoy
              Japan one step deeper. We believe that our tours are carefully designed to provide you
              with the best immersion in the Japanese culture. We promise, you will go home with a
              smile on your face and wonderful lasting memories.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="merits-item">
            <img
              src="/images/top/merit_2.png"
              loading="lazy"
              alt="Merit 2 image"
              aria-hidden="true"
            />
            <h3 className="merits-title">Professional Guide</h3>
            <p>
              We only recruit guides with amazing personalities. All our guides are professionally
              trained and are licensed Japanese tour guides. With our guides, you will get a unique
              experience that you won't find anywhere else.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="merits-item">
            <img
              src="/images/top/merit_3.png"
              loading="lazy"
              alt="Merit 3 image"
              aria-hidden="true"
            />
            <h3 className="merits-title">Prompt assistance with your travel</h3>
            <p>
              We do our best to assist our customers needs and accommodate anything they hope to see
              in Japan. It may be difficult for tourists to book some restaurants or gain access to
              some venues on their own. We can help with that! Some expensive hotels have concierge
              service that may be of help to tourists, but this is not always the case. If you wish
              to arrange for our services, we would be happy to be of help.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <button type="button" className="button-prev" aria-label="Prev Merit">
        <SVG
          src="/images/icons/keyboard_arrow_left.svg"
          className="icon"
          aria-hidden="true"
          role="presentation"
        />
      </button>
      <button type="button" className="button-next" aria-label="Next Merit">
        <SVG
          src="/images/icons/keyboard_arrow_right.svg"
          className="icon"
          aria-hidden="true"
          role="presentation"
        />
      </button>
    </MeritsWrap>
  )
}

const MeritsWrap = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    .swiper-slide {
      padding: 0 16px;
    }
  }
  .button-prev,
  .button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100px;
    width: 36px;
    height: 36px;
    padding: 0;
    color: ${COLORS.white};
    border-radius: 50%;
    background: ${COLORS.primary};
    border: 0;
    cursor: pointer;
    z-index: 1000;
    @media (min-width: ${BREAKPOINT_TABLET_SMALL}px) {
      display: none;
    }
  }
  .button-prev {
    left: 16px;
  }
  .button-next {
    right: 16px;
  }
  .merits-item {
    display: block;
    margin: 0 auto;
    > img {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: auto;
      aspect-ratio: 1/1;
      @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
        max-width: 200px;
      }
    }

    .merits-title {
      margin-top: 16px;
      color: ${COLORS.primary};
      font-size: 22px;
      text-align: center;
      @media (max-width: ${BREAKPOINT_DESKTOP}px) {
        font-size: 18px;
      }
    }

    > p {
      margin-top: 28px;
      font-size: 16px;
      line-height: 26px;
      @media (max-width: ${BREAKPOINT_DESKTOP}px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`

export default Merits
